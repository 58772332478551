<template>
    <v-app-bar
        id="navbar"
        :class="isLgAndUp ? 'd-none d-lg-flex tw-h-32 !tw-border-b' : 'd-lg-none d-flex tw-h-16 !tw-border-b !tw-top-0'"
        :border="isLgAndUp"
        flat>
        <template v-if="!isLgAndUp">
            <v-img
                class="tw-ml-8"
                max-width="110"
                src="/images/blue_crow_logo.png"></v-img>
            <v-app-bar-nav-icon
                class="tw-mr-6 !tw-text-secondary"
                variant="text"
                @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        </template>
        <div
            v-else
            class="container-xl tw-flex tw-flex-row tw-justify-between tw-items-center">
            <v-img
                max-width="140"
                src="/images/blue_crow_logo.png"></v-img>
            <div class="tw-flex tw-flex-row tw-gap-12">
                <!-- <v-btn
                    :to="{ name: 'AboutUs' }"
                    variant="plain"
                    id="nabvar-button"
                    v-ripple="false"
                    class="!tw-font-semibold !tw-text-base text-capitalize hover:!tw-font-bold hover:!tw-underline hover:!tw-decoration-primary hover:!tw-decoration-2"
                    >{{ $t('commercial.navbar.aboutUs') }}</v-btn
                > -->
                <!-- <v-btn
                    :to="{ name: 'Invest' }"
                    variant="plain"
                    id="nabvar-button"
                    v-ripple="false"
                    class="!tw-font-semibold !tw-text-base text-capitalize hover:!tw-font-bold hover:!tw-underline hover:!tw-decoration-primary hover:!tw-decoration-2"
                    >{{ $t('commercial.navbar.invest') }}</v-btn
                >
                <v-btn
                    :to="{ name: 'Sell' }"
                    variant="plain"
                    id="nabvar-button"
                    v-ripple="false"
                    class="!tw-font-semibold !tw-text-base text-capitalize hover:!tw-font-bold hover:!tw-underline hover:!tw-decoration-primary hover:!tw-decoration-2"
                    >{{ $t('commercial.navbar.sell') }}</v-btn
                >
                <v-btn
                    :to="{ name: 'Raise' }"
                    variant="plain"
                    id="nabvar-button"
                    v-ripple="false"
                    class="tw-text-secondary !tw-font-semibold !tw-text-base text-capitalize hover:!tw-font-bold hover:!tw-underline hover:!tw-decoration-primary hover:!tw-decoration-2"
                    >{{ $t('commercial.navbar.raise') }}</v-btn
                > -->
            </div>
            <div class="tw-flex tw-items-center tw-gap-x-4">
                <v-btn
                    :to="{ path: 'auth/login' }"
                    rounded="xl"
                    class="bg-primary text-black font-weight-bold"
                    size="large"
                    >{{ $t('commercial.navbar.loginOrRegister') }}</v-btn
                >
                <div class="tw-flex tw-gap-x-4">
                    <span
                        class="fi fi-pt hover:tw-cursor-pointer"
                        @click="setLanguage('pt')"></span>
                    <span
                        class="fi fi-gb hover:tw-cursor-pointer"
                        @click="setLanguage('en')"></span>
                </div>
            </div>
        </div>
    </v-app-bar>
    <v-navigation-drawer
        v-model="drawer"
        disable-resize-watcher
        disable-scroll
        class="!tw-top-14 !tw-h-full !tw-w-screen">
        <div></div>
        <div class="!tw-flex !tw-flex-col !tw-justify-between">
            <v-list
                bg-color="text-primary"
                class="tw-flex tw-flex-col tw-items-center">
                <!-- <v-list-item link :to="{ name: 'AboutUs' }" class="tw-block tw-w-full !tw-font-semibold !tw-text-secondary tw-text-center !tw-border-b !tw-py-6">
                    {{ $t('commercial.navbar.aboutUs') }}
                </v-list-item> -->
                <!-- <v-list-item link :to="{ name: 'Invest' }" class="tw-block tw-w-full !tw-font-semibold !tw-text-secondary tw-text-center !tw-border-b !tw-py-6">
                    {{ $t('commercial.navbar.invest') }}
                </v-list-item>
                <v-list-item link :to="{ name: 'Sell' }" class="tw-block tw-w-full !tw-font-semibold !tw-text-secondary tw-text-center !tw-border-b !tw-py-6">
                    {{ $t('commercial.navbar.sell') }}
                </v-list-item>
                <v-list-item link :to="{ name: 'Raise' }" class="tw-block tw-w-full !tw-font-semibold !tw-text-secondary tw-text-center !tw-border-b !tw-py-6">
                    {{ $t('commercial.navbar.raise') }}
                </v-list-item> -->
            </v-list>
            <div class="tw-text-center">
                <v-btn
                    :to="{ name: 'Auth-Login' }"
                    rounded="xl"
                    class="bg-primary text-black font-weight-bold"
                    size="large"
                    >{{ $t('commercial.navbar.loginOrRegister') }}</v-btn
                >
                <div class="tw-flex tw-text-center tw-justify-center tw-w-14 !tw-mx-auto tw-mt-4 tw-gap-x-4">
                    <span
                        class="fi fi-pt hover:tw-cursor-pointer"
                        @click="setLanguage('pt')"></span>
                    <span
                        class="fi fi-gb hover:tw-cursor-pointer"
                        @click="setLanguage('en')"></span>
                </div>
            </div>
        </div>
    </v-navigation-drawer>
</template>

<script setup lang="ts">
    import { computed } from 'vue';
    import { onMounted, onBeforeMount } from 'vue';
    import { ref, watch, nextTick } from 'vue';
    import { useDisplay } from 'vuetify';
    import { useI18n } from 'vue-i18n';
    import { useLanguageStore } from '@/store/modules/language';

    const i18n = useI18n();
    const store = useLanguageStore();

    const setLanguage = (language: string) => {
        i18n.locale.value = language;
        store.setLanguage(language);
    };

    setLanguage(store.language);

    const drawer = ref(false);

    const display = useDisplay();

    const emit = defineEmits(['isMobile']);

    const isLgAndUp = computed(() => {
        const { lgAndUp } = display;
        return lgAndUp.value;
    });

    async function checkNavbar() {
        await nextTick();

        let navbar = document.getElementById('navbar');
        // set css variable for navbar height
        if (navbar) {
            document.documentElement.style.setProperty('--navbar-height', `${navbar.clientHeight}px`);
            let main = document.querySelector('.v-application__wrap > main') as HTMLElement;
            if (main) {
                main.style.setProperty('--v-layout-top', `${navbar.clientHeight}px`);
            }
        }
    }

    watch(isLgAndUp, (value) => {
        if (value) {
            drawer.value = false;
            emit('isMobile', false);
        }
        if (!value) emit('isMobile', true);
        checkNavbar();
    });

    // Check screen size before the component is inserted into the DOM
    // and set the drawer state accordingly
    onBeforeMount(() => {
        if (isLgAndUp.value) {
            emit('isMobile', false);
        }
        if (!isLgAndUp.value) {
            emit('isMobile', true);
        }
    });

    onMounted(() => {
        checkNavbar();
    });
</script>

<style scoped>
    #nabvar-button {
        letter-spacing: normal;
        color: #131d3c;
        opacity: 1;
    }
</style>
<style>
    .v-list-item--active > .v-list-item__overlay,
    .v-list-item[aria-haspopup='menu'][aria-expanded='true'] > .v-list-item__overlay {
        opacity: 0 !important;
    }
</style>
