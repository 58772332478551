<template>
    <Navbar @isMobile="updateLayoutClass" />
    <v-main class="tw-bg-white-dark" :class="{ 'mobile-layout': mobile }">
        <router-view></router-view>
    </v-main>
    <Footer />
</template>

<script setup lang="ts">
    import Navbar from './Navbar.vue';
    import Footer from './Footer.vue';
    import { ref } from 'vue';

    const mobile = ref(true);

    const updateLayoutClass = (value) => {
        mobile.value = value;
    };
</script>
<style scoped>
    .mobile-layout {
        /* --v-layout-top: 1.75rem !important; */
    }
</style>
