<template>
    <v-container
        class="tw-flex tw-flex-col tw-gap-4 container"
        v-if="loading == false">
        <div class="tw-flex tw-flex-col xl:tw-flex xl:tw-flex-row xl:tw-justify-between">
            <h1 class="tw-font-bold tw-text-secondary tw-text-center md:tw-text-left tw-text-3xl tw-underline tw-underline-offset-1 tw-decoration-primary tw-decoration-4 tw-mb-4">
                {{ $t('reservedArea.helpfulDocuments.myDocuments') }}
            </h1>
            <!-- My documents (> 1280px)-->
            <!-- <div class="tw-text-left tw-mt-0 tw-hidden xl:tw-block">
                <v-btn
                    type="submit"
                    class="bg-gray-light text-black font-weight-bold !tw-text-base !tw-py-1.5 tw-mr-3.5 focus:!tw-bg-secondary focus:!tw-text-white"
                    max-height="none"
                    height="auto"
                    rounded="xs"
                    size="x-large">
                    {{ $t('reservedArea.helpfulDocuments.myDocuments') }}
                </v-btn>
            </div> -->
        </div>
        <!-- Breadcrumbs -->
        <!-- <div class="tw-text-lg tw-text-secondary tw-text-left tw-hidden md:tw-block tw-mb-4">
            <v-breadcrumbs :items="breadCrumbItems" active-class="!tw-font-semibold" class="!tw-pl-0">
                <template v-slot:divider>
                    <v-icon icon="mdi-chevron-right"></v-icon>
                </template>
            </v-breadcrumbs>
        </div> -->
        <!-- My documents (< 1280px)-->
        <!-- <div class="tw-text-center md:tw-text-left tw-mb-3 tw-block xl:tw-hidden">
            <v-btn
                type="submit"
                class="bg-gray-light text-black font-weight-bold !tw-text-base !tw-py-1.5 tw-mr-3.5 !tw-mb-4 focus:!tw-bg-secondary focus:!tw-text-white"
                max-height="none"
                height="auto"
                rounded="xs"
                size="x-large">
                {{ $t('reservedArea.helpfulDocuments.myDocuments') }}
            </v-btn>
        </div> -->
        <!-- Table -->
        <v-data-table-server
            v-model:items-per-page="itemsPerPage"
            :items-per-page-text="$t('reservedArea.helpfulDocuments.filesPerPage')"
            :headers="headers"
            :items-length="totalItems"
            :items="serverItems"
            :loading="loading"
            :search="search"
            class="!tw-rounded-lg tw-mt-2 lg:tw-mt-0"
            item-value="name"
            @update:options="loadItems">
            <template #top>
                <v-row class="tw-p-4">
                    <v-col
                        cols="12"
                        xs="12"
                        sm="12"
                        md="4">
                        <v-text-field
                            clearable
                            hide-details
                            :placeholder="$t('reservedArea.helpfulDocuments.search')"
                            v-model="searchDocument"
                            class="ma-2"
                            variant="outlined"
                            density="compact"></v-text-field>
                    </v-col>
                    <v-col
                        cols="12"
                        xs="6"
                        sm="6"
                        md="3">
                        <v-autocomplete
                            clearable
                            id="theme"
                            hide-details
                            :label="$t('reservedArea.helpfulDocuments.topic')"
                            :items="themesItems"
                            v-model="selectedTheme"
                            class="ma-2 tw-h-10"
                            variant="outlined"
                            density="compact"></v-autocomplete>
                    </v-col>
                    <v-col
                        cols="12"
                        Sxs="6"
                        sm="6"
                        md="3">
                        <v-autocomplete
                            clearable
                            id="fund"
                            hide-details
                            :label="$t('reservedArea.helpfulDocuments.fund')"
                            :items="fundsItems"
                            v-model="selectedFund"
                            class="ma-2"
                            variant="outlined"
                            density="compact"></v-autocomplete>
                    </v-col>
                </v-row>
            </template>
            <!-- theme -->
            <template #[`item.theme`]="{ item }">
                <div>
                    <p
                        v-if="item.selectable.doctype"
                        class="tw-font-semibold tw-text-secondary tw-text-left tw-text-sm">
                        {{ item.selectable.doctype }}
                    </p>
                    <p
                        v-else
                        class="tw-font-semibold tw-text-secondary tw-text-left tw-text-sm">
                        -
                    </p>
                </div>
            </template>
            <!-- filename -->
            <template #[`item.filename`]="{ item }">
                <div>
                    <p
                        v-if="item.selectable.fileName"
                        class="tw-font-semibold tw-text-secondary tw-text-left tw-text-sm">
                        {{ item.selectable.fileName }}
                    </p>
                    <p
                        v-else
                        class="tw-font-semibold tw-text-secondary tw-text-left tw-text-sm">
                        -
                    </p>
                </div>
            </template>
            <!-- date -->
            <template #[`item.date`]="{ item }">
                <div>
                    <p
                        v-if="item.selectable.date"
                        class="tw-font-semibold tw-text-secondary tw-text-left tw-text-sm">
                        {{
                            new Date(item.selectable.date).toLocaleDateString(undefined, {
                                year: 'numeric',
                                month: '2-digit',
                                day: '2-digit',
                            })
                        }}
                    </p>
                    <p
                        v-else
                        class="tw-font-semibold tw-text-secondary tw-text-left tw-text-sm">
                        -
                    </p>
                </div>
            </template>
            <!-- Langueage -->
            <template #[`item.language`]="{ item }">
                <div>
                    <p
                        v-if="item.selectable.language"
                        class="tw-font-semibold tw-text-secondary tw-text-left tw-text-sm">
                        <span :class="{'fi fi-pt' : item.selectable.language == 'pt', 'fi fi-gb' : item.selectable.language == 'en'}"></span>
                    </p>
                    <p
                        v-else
                        class="tw-font-semibold tw-text-secondary tw-text-left tw-text-sm">
                        -
                    </p>
                </div>
            </template>
            <template #[`item.actions`]="{ item }">
                <v-btn
                variant="flat"
                    type="submit"
                    @click="downloadFile(item.selectable.id, item.selectable.fileName)"
                    class="bg-primary text-black font-weight-bold !tw-text-sm !tw-py-1.5"
                    max-height="none"
                    height="auto"
                    rounded="xs"
                    size="x-large">
                    <v-icon>
                        <v-img
                            height="16"
                            src="/images/ReservedArea/icons/download.svg"></v-img>
                    </v-icon>
                    <p class="tw-ml-2">{{ $t('reservedArea.helpfulDocuments.download') }}</p>
                </v-btn>
            </template>
        </v-data-table-server>
    </v-container>
    <div
        v-if="loading"
        class="tw-h-full tw-flex tw-items-center tw-justify-center">
        <v-progress-circular
            indeterminate
            color="primary"
            model-value="20"></v-progress-circular>
    </div>
</template>

<script setup lang="ts">
    import { ref, watch, computed } from 'vue';
    import { VDataTableServer } from 'vuetify/labs/VDataTable';
    import { useI18n } from 'vue-i18n';
    import { useRouter } from 'vue-router';
    import { getDocument, getCustomerFunds, getCustomerDocuments } from '@/api/dbn';
    import { useAuthStore } from '@/store/modules/auth';
    import { useLanguageStore } from '@/store/modules/language';

    const { t } = useI18n();

    const $router = useRouter();

    //breadcrumbs
    // const breadCrumbItems = ref([
    //     {
    //         title: t('reservedArea.breadcrumbs.dashboard'),
    //         disabled: false,
    //         href: '/reserved-area/dashboard',
    //     },
    //     {
    //         title: t('reservedArea.breadcrumbs.helpfulDocuments'),
    //         disabled: false,
    //         active: $router.currentRoute.value.name === 'HelpfulDocument' ? true : false,
    //         href: '/reserved-area/helpful-document',
    //     },
    // ]);
    //data table
    const itemsPerPage = ref(15);
    const headers = ref([
        {
            title: t('reservedArea.helpfulDocuments.topic'),
            align: 'start',
            sortable: false,
            key: 'theme',
        },
        { title: t('reservedArea.helpfulDocuments.file'), key: 'filename' },
        { title: t('reservedArea.helpfulDocuments.date'), key: 'date' },
        { title: t('reservedArea.helpfulDocuments.language'), key: 'language' },
        { title: '', key: 'actions', align: 'end' },
    ]);
    const totalItems = ref(0);
    const loading = ref(true);
    const serverItems = ref([] as any[]);
    const fundsItems = ref([] as any[]);
    const themesItems = ref([] as any[]);
    const search = ref('');
    const searchDocument = ref('');
    const selectedTheme = ref('');
    const selectedFund = ref('');

    //language store
    const languageStore = useLanguageStore();
    const language = computed(() => languageStore.getLanguage);

    //store token
    const authStore = useAuthStore();
    const token = authStore.getToken;
    const user = authStore.getUser;

    //customer id and investments
    const documents = ref([]);
    const base64 = ref('');

    //watch search, theme and fund
    watch(searchDocument, () => {
        search.value = searchDocument.value;
    });
    watch(selectedTheme, () => {
        search.value = selectedTheme.value;
    });
    watch(selectedFund, () => {
        search.value = selectedFund.value;
    });

    const fetchDocuments = () => {
        getCustomerDocuments(language.value, user?.dbnCode ? user?.dbnCode : user?.dbns[0]?.code, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((response) => {
                documents.value = response.data;
                themesItems.value = Array.from(new Set(documents.value.map((document) => document.doctype))).filter((theme) => theme !== '');
                // fundsItems.value = Array.from(new Set(documents.value.map((document) => document.fileName)));
            })
            .finally(() => {
                loadItems({ page: 1, itemsPerPage: 15, sortBy: [] });
            })
            .catch((error) => {
                console.log(error);
                loading.value = false;
            });
    };

    fetchDocuments();

    //load table items
    const loadItems = async ({ page, itemsPerPage, sortBy }: any) => {
        //filter documents
        let filteredDocuments = documents.value;
        //by fund
        selectedFund.value !== '' && selectedFund.value !== null ? (filteredDocuments = filteredDocuments.filter((document) => document.fileName === selectedFund.value)) : 0;
        //by theme
        selectedTheme.value !== '' && selectedTheme.value !== null ? (filteredDocuments = filteredDocuments.filter((document) => document.doctype === selectedTheme.value)) : 0;
        //by search
        searchDocument.value !== '' && searchDocument.value !== null
            ? (filteredDocuments = filteredDocuments.filter((document) => document.fileName.toLowerCase().includes(searchDocument.value.toLowerCase())))
            : 0;

        //sort documents
        if (sortBy.length) {
            const sortKey = sortBy[0].key;
            const sortOrder = sortBy[0].order;
            filteredDocuments = filteredDocuments.sort((a, b) => {
                const sortA = a[sortKey];
                const sortB = b[sortKey];
                if (sortOrder === 'asc') {
                    return sortA > sortB ? 1 : -1;
                } else if (sortOrder === 'desc') {
                    return sortA < sortB ? 1 : -1;
                }
                return 0;
            });
        }

        serverItems.value = filteredDocuments?.length > 0 ? filteredDocuments.slice((page - 1) * itemsPerPage, page * itemsPerPage) : [];
        totalItems.value = filteredDocuments?.length > 0 ? filteredDocuments.length : 0;
        loading.value = false;
    };

    //download file
    const downloadFile = async (documentId: any, name: any) => {
        await fetchFundDocument(documentId);
        const linkSource = `data:application/pdf;base64,${base64.value}`;
        const downloadLink = document.createElement('a');
        const fileName = name;

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    };

    const fetchFundDocument = (documentId: any) =>
        getDocument(documentId, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((response) => {
                base64.value = response.data.base64;
            })
            .catch((error) => {
                console.log(error);
            });
</script>

<style scoped></style>

<style>
    .v-data-table-header__content {
        color: #b0b0b0;
        font-weight: 600 !important;
    }
    .v-data-table .v-table__wrapper > table > thead > tr > th,
    .v-data-table .v-table__wrapper > table tbody > tr > th {
        background-color: #f8f8f8;
    }
    .v-input__control {
        height: 3rem;
    }
    .v-field__input {
        margin-top: 0.625rem;
    }
</style>
