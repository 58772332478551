<template>
    <v-container
        class="tw-flex tw-flex-col tw-gap-4 container"
        v-if="isLoading == false">
        <h1 class="tw-font-bold tw-text-secondary tw-text-center md:tw-text-left tw-text-3xl tw-underline tw-underline-offset-1 tw-decoration-primary tw-decoration-4 tw-mb-4">
            {{ $t('reservedArea.talkToUs.talkToUs') }}
        </h1>
        <!-- Breadcrumbs -->
        <!-- <div class="tw-text-lg tw-text-secondary tw-text-left tw-hidden md:tw-block">
            <v-breadcrumbs :items="breadCrumbItems" active-class="!tw-font-semibold" class="!tw-pl-0">
                <template v-slot:divider>
                    <v-icon icon="mdi-chevron-right"></v-icon>
                </template>
            </v-breadcrumbs>
        </div> -->
        <h2 class="tw-font-bold tw-text-secondary tw-text-center md:tw-text-left tw-text-xl">{{ $t('reservedArea.talkToUs.blueCrowTeam') }}</h2>
        <!-- Cards -->
        <v-row>
            <v-col
                cols=""
                xs="12"
                sm="6"
                md="6"
                lg="6"
                v-for="contact in contacts"
                :key="contact">
                <v-card
                    variant="flat"
                    rounded="lg">
                    <div class="tw-ml-6 tw-mb-10">
                        <div class="tw-flex tw-justify-between">
                            <div>
                                <v-card-text class="!tw-text-left !tw-text-lg tw-text-secondary !tw-pb-0 tw-mb-1">{{ contact.role }}</v-card-text>

                                <v-card-text class="!tw-pb-0">
                                    <p class="tw-font-bold tw-text-left tw-text-xl tw-text-secondary">{{ contact.name }}</p>
                                </v-card-text>
                            </div>
                            <div class="tw-mt-6 tw-mr-10">
                                <v-img
                                    aspect-ratio="1"
                                    src="/images/blue_crow_crow_only.jpg"
                                    cover
                                    class="tw-rounded-full tw-w-28 tw-h-28"></v-img>
                            </div>
                        </div>
                        <v-card-text class="!tw-py-0">
                            <p class="tw-text-left tw-text-xl tw-text-secondary tw-mb-2">{{ contact.phone }}</p>
                            <p class="tw-text-left tw-text-xl tw-text-secondary">{{ contact.email }}</p>
                        </v-card-text>
                    </div>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
    <div
        v-if="isLoading"
        class="tw-h-full tw-flex tw-items-center tw-justify-center">
        <v-progress-circular
            indeterminate
            color="primary"
            model-value="20"></v-progress-circular>
    </div>
</template>

<script setup lang="ts">
    import { ref, watch, computed } from 'vue';
    import { useI18n } from 'vue-i18n';
    import { getContacts } from '@/api/contact';
    import { useLanguageStore } from '@/store/modules/language';
    import { useAuthStore } from '@/store/modules/auth';
    import { useRouter } from 'vue-router';

    const { t } = useI18n();

    const $router = useRouter();
    //breadcrumbs
    // const breadCrumbItems = ref([
    //     {
    //         title: t('reservedArea.breadcrumbs.dashboard'),
    //         disabled: false,
    //         href: '/reserved-area/dashboard',
    //     },
    //     {
    //         title: t('reservedArea.breadcrumbs.talkToUs'),
    //         disabled: false,
    //         active: $router.currentRoute.value.name == 'TalkToUs' ? true : false,
    //         href: '/reserved-area/talk-to-us',
    //     },
    // ]);

    const languageStore = useLanguageStore();
    const language = computed(() => languageStore.getLanguage);

    const authStore = useAuthStore();
    const token = authStore.getToken;

    const contacts = ref([]);
    const isLoading = ref(false);

    //change contacts language
    watch(language, () => {
        fetchContacts();
    });

    //get contacts
    const fetchContacts = () =>
        getContacts(
            {
                locale: language.value == 'pt' ? 'pt' : 'en',
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            },
        ).then((response) => {
            contacts.value = response.data.data;
            isLoading.value = false;
        });

    fetchContacts();
</script>

<style scoped></style>
