<template>
    <!-- Our Passion for Investing -->
    <div class="container-md full-h tw-flex tw-items-center">
        <v-row class="tw-flex-col tw-items-center tw-text-center md:tw-flex-row md:tw-text-left !tw-mx-auto">
            <v-col>
                <h1 class="tw-font-extrabold tw-text-secondary tw-text-center md:tw-text-left tw-text-3xl md:tw-text-4xl lg:tw-text-5xl 2xl:tw-text-6xl tw-mb-8 tw-mt-2 !tw-leading-snug">
                    <span class="underline">{{ $t('commercial.home.title1') }}</span>
                    <span class="!tw-block !tw-text-center md:!tw-text-left">{{ $t('commercial.home.title2') }}</span>
                </h1>
                <v-col class="!tw-pl-0 !tw-flex tw-justify-center md:!tw-hidden">
                    <div class="tw-flex tw-flex-row tw-items-end tw-gap-x-8 tw-mb-8">
                        <div class="tw-bg-primary tw-aspect-square tw-h-7 tw-w-7"></div>
                        <div class="tw-h-full tw-relative">
                            <div class="overlap-div tw-absolute tw-z-0 tw-bg-secondary tw-h-5/6 tw-w-11/12"></div>
                            <img src="/images/Auth/banner.png" class="tw-relative tw-max-h-80 tw-z-10 tw-aspect-square tw-object-cover tw-border tw-border-solid tw-border-gray-darker" alt="" />
                        </div>
                    </div>
                </v-col>
                <h2 class="tw-font-semibold tw-text-secondary tw-text-center tw-text-xl md:tw-text-left xl:tw-text-2xl 2xl:tw-text-4xl tw-mb-8">{{ $t('commercial.home.subtitle1') }}</h2>
                <v-btn
                    target="_blank"
                    href="https://www.bluecrowcapital.com/en/"
                    type="submit"
                    class="bg-primary text-black font-weight-bold !tw-px-16 !tw-py-2 !tw-text-2xl lg:!tw-text-3xl"
                    max-height="none"
                    height="auto"
                    rounded="xl"
                    size="x-large">
                    {{ $t('commercial.home.seeMore') }}
                </v-btn>
            </v-col>
            <v-col class="!tw-pl-0 !tw-hidden md:!tw-block">
                <div class="tw-flex tw-flex-row tw-items-end tw-gap-x-8">
                    <div class="tw-bg-primary tw-aspect-square tw-h-7 tw-w-7"></div>
                    <div class="tw-h-full tw-w-full tw-relative">
                        <div class="overlap-div tw-absolute tw-z-0 tw-bg-secondary tw-h-5/6 tw-w-11/12"></div>
                        <v-img cover src="/images/Auth/banner.png" class="tw-absolute tw-z-10 tw-aspect-square !tw-border !tw-border-solid !tw-border-gray-darker"></v-img>
                    </div>
                </div>
            </v-col>
        </v-row>
    </div>
    <!-- €453M Assets under Management -->
    <div class="tw-bg-secondary">
        <div class="container-md">
            <v-row no-gutters justify="center" align-content="center" class="tw-text-center !tw-p-0 !tw-m-0">
                <v-col cols="12" md="5" lg="4" class="!tw-py-4 xl:!tw-py-9 md:tw-border-r tw-border-white-dark tw-flex tw-flex-col tw-justify-center">
                    <p class="tw-text-2xl xl:tw-text-4xl tw-font-bold tw-text-white-dark">€453M</p>
                    <p class="tw-text-base xl:tw-text-2xl tw-font-semibold tw-text-white-dark">{{ $t('commercial.home.assetsUnderManagement') }}</p>
                </v-col>

                <v-col cols="12" md="2" lg="4" class="!tw-py-4 xl:!tw-py-9 md:tw-border-r tw-border-white-dark tw-flex tw-flex-col tw-justify-center">
                    <p class="tw-text-2xl xl:tw-text-4xl tw-font-bold tw-text-white-dark">#1074</p>
                    <p class="tw-text-base xl:tw-text-2xl tw-font-semibold tw-text-white-dark">{{ $t('commercial.home.clients') }}</p>
                </v-col>
                <v-col cols="12" md="5" lg="4" class="!tw-py-4 xl:!tw-py-9">
                    <p class="tw-text-2xl xl:tw-text-4xl tw-font-bold tw-text-white-dark tw-flex tw-flex-col tw-justify-center">€269M {{ $t('commercial.home.deployed') }}</p>
                    <p class="tw-text-base xl:tw-text-2xl tw-font-semibold tw-text-white-dark">{{ $t('commercial.home.pertainingTo') }}</p>
                </v-col>
            </v-row>
        </div>
    </div>

    <!-- BlueCrow Capital was created -->
    <section class="!tw-my-16 container-md">
        <v-row class="tw-flex-col tw-items-center tw-text-center md:tw-flex-row md:tw-text-left">
            <v-col cols="4" class="!tw-hidden md:!tw-block">
                <v-img cover height="544px" src="/images/Auth/banner.png" class="tw-aspect-square !tw-border !tw-border-solid !tw-border-gray-darker"></v-img>
            </v-col>
            <v-img cover height="400px" src="/images/Auth/banner.png" class="md:!tw-hidden tw-aspect-square !tw-border !tw-border-solid !tw-border-gray-darker"></v-img>
            <v-col>
                <p class="tw-text-base lg:tw-text-xl 2xl:tw-text-2xl tw-text-center md:tw-text-left tw-text-secondary" v-html="$t('commercial.home.text1')"></p>
            </v-col>
        </v-row>
    </section>

    <!-- Our story -->
    <div class="tw-bg-gray-lighter tw-py-24">
        <div class="container-xl tw-flex tw-flex-col tw-items-center">
            <h1 class="tw-font-extrabold tw-text-center tw-text-secondary tw-text-3xl md:tw-text-4xl xl:tw-text-5xl tw-mb-16 tw-underline tw-underline-offset-1 tw-decoration-primary tw-decoration-8">
                {{ $t('commercial.home.title3') }}
            </h1>
            <img v-if="$i18n.locale === 'en'" class="!tw-hidden md:!tw-block" srcset="/images/Commercial/our-story.png 1x, /images/Commercial/our-story@2x.png 2x" alt="" />
            <img v-if="$i18n.locale === 'pt'" class="!tw-hidden md:!tw-block" src="/images/Commercial/our-story-desktop-pt.png" alt="" />
            <img v-if="$i18n.locale === 'en'" class="!tw-block md:!tw-hidden" width="140" src="/images/Commercial/our-story-mobile-en.png" alt="" />
            <img v-if="$i18n.locale === 'pt'" class="!tw-block md:!tw-hidden" width="140" src="/images/Commercial/our-story-mobile-pt.png" alt="" />
        </div>
    </div>
</template>

<script setup lang="ts"></script>

<style scoped>
    h1.tw-underline {
        text-decoration-skip-ink: none;
    }
    .overlap-div {
        right: 11%;
        top: 19%;
    }
</style>
