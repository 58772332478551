<template>
    <div class="container-parent d-flex align-center justify-center tw-h-full">
        <v-container>
            <h2 class="tw-font-extrabold tw-text-center tw-text-secondary tw-text-4xl tw-underline tw-underline-offset-0 tw-decoration-primary tw-mb-6">{{ $t('auth.resetPassword.title1') }}</h2>
            <h2 class="tw-font-semibold tw-text-center tw-text-secondary tw-text-2xl tw-mb-20">{{ $t('auth.resetPassword.subtitle1') }}</h2>
            <v-form>
                <v-text-field
                    class="tw-mb-6"
                    name="password"
                    variant="outlined"
                    :placeholder="$t('auth.resetPassword.password')"
                    :rules="[(v) => rules.required(v, $t('auth.resetPassword.requiredField')), (v) => rules.min(v, 6, $t('auth.resetPassword.invalidPasswordLength'))]"
                    :type="isPasswordVisible ? 'text' : 'password'"
                    :append-inner-icon="isPasswordVisible ? 'mdi-eye-off' : 'mdi-eye'"
                    @click:append-inner="isPasswordVisible = !isPasswordVisible"
                    hide-details="auto"
                    v-model="password" />
                <v-text-field
                    class="tw-mb-6"
                    name="passwordConfirmation"
                    variant="outlined"
                    :placeholder="$t('auth.resetPassword.confirmPassword')"
                    :rules="[
                        (v) => rules.required(v, $t('auth.resetPassword.requiredField')),
                        (v) => rules.min(v, 6, $t('auth.resetPassword.invalidPasswordLength')),
                        (v) => rules.comparePassword(v, password, $t('auth.resetPassword.passwordsDontMatch')),
                    ]"
                    :type="isPasswordConfirmationVisible ? 'text' : 'password'"
                    :append-inner-icon="isPasswordConfirmationVisible ? 'mdi-eye-off' : 'mdi-eye'"
                    @click:append-inner="isPasswordConfirmationVisible = !isPasswordConfirmationVisible"
                    hide-details="auto"
                    v-model="passwordConfirmation" />
                <div class="tw-flex tw-justify-end tw-z-10 tw-mt-[-12px] !tw-mb-4" v-show="success">
                    <v-alert class="!tw-justify-start" dense variant="tonal" type="success"
                        >{{ $t('auth.resetPassword.successText') }}<router-link to="/auth/login" class="tw-font-bold">{{ $t('auth.resetPassword.backToLogin') }}</router-link
                        >.</v-alert
                    >
                </div>
                <div class="tw-flex tw-justify-center tw-mb-12">
                    <v-btn
                        type="submit"
                        class="bg-primary text-black font-weight-bold !tw-px-12 !tw-max-h-none !tw-h-auto !tw-py-4"
                        rounded="xl"
                        size="x-large"
                        color="primary"
                        @click.prevent="submitResetPassword()">
                        {{ $t('auth.resetPassword.submit') }}
                    </v-btn>
                </div>
            </v-form>
            <div class="tw-flex tw-text-center tw-justify-center tw-w-14 tw-mx-auto tw-mt-4 tw-gap-x-4">
                <span class="fi fi-pt hover:tw-cursor-pointer" @click="setLanguage('pt')"></span>
                <span class="fi fi-gb hover:tw-cursor-pointer" @click="setLanguage('en')"></span>
            </div>
        </v-container>
    </div>
</template>

<script setup lang="ts">
    import { ref } from 'vue';
    import { resetPassword } from '@/api/auth';
    import { useI18n } from 'vue-i18n';
    import { useLanguageStore } from '@/store/modules/language';
    import { useRouter } from 'vue-router';
    import { useRoute } from 'vue-router';
    import rules from '@/composables/rules';

    const i18n = useI18n();
    const store = useLanguageStore();

    const { t: $t } = useI18n();
    const $router = useRouter();

    const password = ref('');
    const passwordConfirmation = ref('');
    const isPasswordVisible = ref(false);
    const isPasswordConfirmationVisible = ref(false);
    const success = ref(false);

    const $route = useRoute();

    const setLanguage = (language: string) => {
        i18n.locale.value = language;
        store.setLanguage(language);
    };

    setLanguage(store.language);

    const submitResetPassword = async () => {
        try {
            await resetPassword({
                password: password.value,
                passwordConfirmation: passwordConfirmation.value,
                code: $route.query.code as string,
            });
            success.value = true;
        } catch (err) {
            console.log(err);
        }
    };
</script>

<style scoped>
    .container-parent {
        max-width: min(90%, 31.25rem);
        margin-inline: auto;
    }
    h2.tw-underline {
        text-decoration-skip-ink: none;
    }
</style>
