<template>
    <v-container
        class="container"
        v-if="isLoading == false">
        <div class="tw-flex tw-flex-col md:tw-justify-between md:tw-flex-row">
            <h1 class="tw-font-bold tw-text-secondary tw-text-center md:tw-text-left tw-text-3xl tw-underline tw-underline-offset-1 tw-decoration-primary tw-decoration-4 tw-mb-4">
                {{ fund[0].name }}
            </h1>
        </div>
        <!-- Breadcrumbs -->
        <div class="tw-text-lg tw-text-secondary tw-text-left tw-hidden md:tw-block">
            <v-breadcrumbs
                :items="breadCrumbItems"
                active-class="!tw-font-semibold"
                class="!tw-pl-0">
                <template v-slot:divider>
                    <v-icon icon="mdi-chevron-right"></v-icon>
                </template>
            </v-breadcrumbs>
        </div>
        <div class="tw-bg-white tw-rounded-lg tw-mt-9">
            <v-row class="tw-justify-center tw-gap-x-4 tw-p-4">
                <!-- 1st Brief -->
                <v-col
                    cols="12"
                    class="tw-bg-gray-lighter-lighter tw-rounded-lg tw-mt-[-0.75rem]">
                    <p class="tw-font-bold tw-text-secondary tw-text-left tw-text-xl tw-mb-2">{{ $t('reservedArea.investmentDetails.brief') }}</p>
                    <p class="tw-text-secondary tw-text-left tw-text-lg">
                        {{ fund[0].excerpt ? fund[0].excerpt : 'N/A' }}
                    </p>
                </v-col>
            </v-row>
            <!-- Investments by Categories -->
            <v-expansion-panels class="!tw-p-1 !tw-z-auto !tw-rounded-lg">
                <v-expansion-panel
                    v-for="f in fund"
                    :key="f"
                    class="tw-gap-y-4">
                    <v-expansion-panel-title v-if="f.investments !== null">
                        <template v-slot:default="{ expanded }">
                            <v-col cols="12">
                                <!-- <p class="tw-font-bold tw-text-secondary tw-text-left tw-text-xl tw-ml-[-1.625rem]">{{ investment.category }}</p> -->
                                <p class="tw-font-bold tw-text-secondary tw-text-left tw-text-xl tw-ml-[-1.625rem]">{{ $t('reservedArea.investmentDetails.category') + f.investments[0].category }}</p>
                            </v-col>
                            <v-fade-transition leave-absolute>
                                <span v-if="expanded"></span>
                            </v-fade-transition>
                        </template>
                    </v-expansion-panel-title>
                    <v-expansion-panel-text>
                        <v-row class="tw-justify-center">
                            <v-col
                                cols="12"
                                xs="12"
                                sm="6"
                                md="6"
                                xl="6"
                                xxl="3"
                                class="!tw-px-1"
                                v-for="i in 4"
                                :key="i">
                                <v-card class="tw-mx-auto" variant="flat" rounded="lg">
                                    <template v-slot:title>
                                        <p
                                            v-if="i == 1"
                                            class="tw-font-bold tw-text-secondary tw-text-left tw-text-lg">
                                            {{ $t('reservedArea.investmentDetails.upValue') }}
                                        </p>
                                        <p
                                            v-if="i == 2"
                                            class="tw-font-bold tw-text-secondary tw-text-left tw-text-lg">
                                            {{ $t('reservedArea.investmentDetails.currentValueIn') }}
                                        </p>
                                        <p
                                            v-if="i == 3"
                                            class="tw-font-bold tw-text-secondary tw-text-left tw-text-lg">
                                            {{ $t('reservedArea.investmentDetails.distributedValue') }}
                                        </p>
                                        <p
                                            v-if="i == 4"
                                            class="tw-font-bold tw-text-secondary tw-text-left tw-text-lg">
                                            {{ $t('reservedArea.investmentDetails.distributionDate') }}
                                        </p>
                                    </template>
                                    <template v-slot:text>
                                        <p
                                            v-if="i == 1 || i == 2"
                                            class="tw-text-secondary tw-text-left tw-text-base">
                                            {{ f.priceDate ? f.priceDate.substring(0, 10) : 'N/A' }}
                                        </p>
                                        <p
                                            v-if="i == 3"
                                            class="tw-text-secondary tw-text-left tw-text-base">
                                            &nbsp;
                                        </p>
                                        <p
                                            v-if="i == 4"
                                            class="tw-text-secondary tw-text-left tw-text-base">
                                            {{ f.investments[0]?.nextDistribution ? f.investments[0]?.nextDistribution.substring(0, 10) : 'N/A' }}
                                        </p>
                                    </template>
                                    <v-card-actions>
                                        <v-list-item class="w-100">
                                            <!-- Up Value -->
                                            <v-list-item-title v-if="i == 1">
                                                <p class="tw-font-bold tw-text-secondary tw-text-left tw-text-lg">
                                                    {{ getUpValue(f.investments) }}
                                                </p>
                                            </v-list-item-title>
                                            <!-- Current Value -->
                                            <v-list-item-title v-if="i == 2">
                                                <p class="tw-font-bold tw-text-secondary tw-text-left tw-text-lg">
                                                    {{ getCurrentValue(f.investments) }}
                                                </p>
                                            </v-list-item-title>
                                            <!-- Distributed Value -->
                                            <v-list-item-title v-if="i == 3">
                                                <p class="tw-font-bold tw-text-secondary tw-text-left tw-text-lg">
                                                    {{ getDistributedValue(f.investments) }}
                                                </p>
                                            </v-list-item-title>
                                            <!-- Distribution Date -->
                                            <v-list-item-title v-if="i == 4">
                                                <p class="tw-font-bold tw-text-secondary tw-text-left tw-text-lg">
                                                    {{ getDistributionDate(f.investments) }}
                                                </p>
                                            </v-list-item-title>
                                            <template v-slot:append>
                                                <div class="tw-flex tw-items-center tw-justify-center tw-bg-gray-default tw-rounded-full tw-h-12 tw-w-12 tw-mt-2">
                                                    <v-list-item-icon>
                                                        <v-icon>
                                                            <v-img
                                                                v-if="i == 1"
                                                                src="/images/ReservedArea/icons/chart.svg"></v-img>
                                                            <v-img
                                                                v-if="i == 2"
                                                                src="/images/ReservedArea/icons/graph.svg"></v-img>
                                                            <v-img
                                                                v-if="i == 3"
                                                                src="/images/ReservedArea/icons/euro.svg"></v-img>
                                                            <v-img
                                                                v-if="i == 4"
                                                                src="/images/ReservedArea/icons/calendar-silhouette.svg"></v-img>
                                                        </v-icon>
                                                    </v-list-item-icon>
                                                </div>
                                            </template>
                                        </v-list-item>
                                    </v-card-actions>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-expansion-panel-text>
                </v-expansion-panel>
            </v-expansion-panels>
            <!-- Description and Informations -->
            <v-row class="tw-flex-col sm:tw-flex-row tw-justify-center tw-gap-y-2 sm:tw-gap-x-4 tw-p-4">
                <v-col
                    cols=""
                    xs="12"
                    md="7"
                    class="tw-bg-gray-lighter-lighter tw-rounded-lg md:tw-mb-[-0.75rem] tw-mb-[.0625rem]">
                    <p class="tw-font-bold tw-text-secondary tw-text-left tw-text-xl tw-mb-2">{{ $t('reservedArea.investmentDetails.description') }}</p>
                    <p class="tw-text-secondary tw-text-left tw-text-lg">
                        {{ fund[0].description ? fund[0].description : 'N/A' }}
                    </p>
                </v-col>
                <v-col
                    cols=""
                    class="tw-bg-gray-lighter-lighter tw-rounded-lg tw-h-fit tw-mb-[-0.75rem]">
                    <p class="tw-font-bold tw-text-secondary tw-text-left tw-text-xl tw-mb-4">{{ $t('reservedArea.investmentDetails.informations') }}</p>
                    <!-- if the fund does not have any documents -->
                    <div
                        class="tw-flex tw-flex-row tw-items-center tw-justify-between tw-bg-white tw-rounded-lg tw-mb-2.5 tw-p-1"
                        v-if="publicDocs.length == 0">
                        <div class="tw-flex tw-gap-x-2">
                            <v-icon>
                                <v-img
                                    height="20"
                                    src="/images/ReservedArea/icons/google-docs.svg"></v-img>
                            </v-icon>
                            <p class="tw-text-secondary tw-text-left tw-text-base">N/A</p>
                        </div>
                        <div>
                            <v-icon>
                                <v-img
                                    height="16"
                                    src="/images/ReservedArea/icons/download2.svg"></v-img>
                            </v-icon>
                        </div>
                    </div>
                    <!-- if the fund has documents -->
                    <div
                        class="tw-flex tw-flex-row tw-items-center tw-justify-between tw-bg-white tw-rounded-lg tw-mb-2.5 tw-p-1"
                        v-else
                        v-for="document in publicDocs"
                        :key="document">
                        <div class="tw-flex tw-gap-x-2">
                            <v-icon>
                                <v-img
                                    height="20"
                                    src="/images/ReservedArea/icons/google-docs.svg"></v-img>
                            </v-icon>
                            <p class="tw-text-secondary tw-text-left tw-text-base">{{ document.fileName ? document.fileName : 'N/A' }}</p>
                        </div>
                        <div>
                            <v-icon @click="downloadFile(document.id, document.fileName)">
                                <v-img
                                    height="16"
                                    src="/images/ReservedArea/icons/download2.svg"></v-img>
                            </v-icon>
                        </div>
                    </div>
                </v-col>
            </v-row>
        </div>
    </v-container>
    <div
        v-if="isLoading"
        class="tw-h-full tw-flex tw-items-center tw-justify-center">
        <v-progress-circular
            indeterminate
            color="primary"
            model-value="20"></v-progress-circular>
    </div>
</template>

<script setup lang="ts">
    import { ref, watch, computed } from 'vue';
    import { useI18n } from 'vue-i18n';
    import { useRouter } from 'vue-router';
    import { getFundDetail, getCustomerFunds, getDocument } from '@/api/dbn';
    import { useAuthStore } from '@/store/modules/auth';
    import { useLanguageStore } from '@/store/modules/language';
    import { useRoute } from 'vue-router';
    import { me } from '@/api/user';
    import { get } from 'lodash';

    const { t } = useI18n();

    const $route = useRoute();
    let fundId = $route.params.id;

    const $router = useRouter();

    //language store
    const languageStore = useLanguageStore();
    const language = computed(() => languageStore.getLanguage);

    //store token
    const authStore = useAuthStore();
    const token = authStore.getToken;
    const user = authStore.getUser;

    const fund = ref([]);
    const publicDocs = ref([]);
    const base64 = ref('');

    const isLoading = ref(true);

    //fetch fund details
    const fetchFundDetails = () =>
        getFundDetail(language.value, fundId, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((response) => {
                fund.value = [response.data];
                if (user?.dbnCode || user?.dbns[0]?.code) fetchCustomerFunds();
                else isLoading.value = false;
            })
            .catch((error) => {
                console.log(error);
            });

    //fetch customer funds
    const fetchCustomerFunds = () =>
        getCustomerFunds(language.value, user?.dbnCode ? user?.dbnCode : user?.dbns[0]?.code, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((response) => {
                if (response.data.filter((customerFund: any) => customerFund.id == fundId).length == 0) {
                    //if the fund is not in the customer's funds, then it will appear only the fund's name, description, etc, because the customer doesn't have that fund in his portfolio
                    isLoading.value = false;
                    return;
                }
                //if the fund is in the customer's funds, then it will appear extra information (e.g. category, value, quantity, distributedValue, etc)
                //if not, it won't appear extra information, only the fund's name, description, etc, because the customer doesn't have that fund in his portfolio
                fund.value = response.data.filter((customerFund: any) => customerFund.id == fundId);
                fund.value.forEach((f: any) => {
                    publicDocs.value = [...new Set([...f.publicDocuments, ...publicDocs.value])];
                });
                isLoading.value = false;
            })
            .catch((error) => {
                console.log(error);
            });

    fetchFundDetails();

    //download file
    const downloadFile = async (documentId: any, name: any) => {
        await fetchFundDocument(documentId);
        const linkSource = `data:application/pdf;base64,${base64.value}`;
        const downloadLink = document.createElement('a');
        const fileName = name;

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    };

    //fetch fund document
    const fetchFundDocument = (documentId: any) =>
        getDocument(documentId, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((response) => {
                base64.value = response.data.base64;
            })
            .catch((error) => {
                console.log(error);
            });

    //watch fund
    watch(fund, () => {
        if (fund.value[0].name) {
            breadCrumbItems.value[1].title = fund.value[0].name;
            breadCrumbItems.value[1].href = `/reserved-area/investment/${fund.value[0].id}`;
        }
    });

    //methods to get values (upValue, currentValue, distributedValue, distributionDate)
    //get up value
    const getUpValue = (investments) => {
        // Calculate the sum of upValue
        const sum = investments.reduce((sum, investment) => {
            if (investment.pricePerUnit == null || investment.pricePerUnit == 0) return sum;
            return sum + investment.pricePerUnit;
        }, 0);

        // Convert the sum to a string with enough precision to avoid rounding issues
        const sumStr = sum.toFixed(10); // Ensure sufficient decimal places for precision
        let decimalPlaces = sumStr.split('.')[1]?.replace(/0+$/, '').length || 0;

        if (decimalPlaces < 2) decimalPlaces = 2;

        return sum.toLocaleString('de-DE', {
            style: 'currency',
            currency: 'EUR',
            minimumFractionDigits: decimalPlaces,
            maximumFractionDigits: decimalPlaces,
        });
    };

    //get current value
    const getCurrentValue = (investments: any) => {
        const sum = investments.reduce((sum, investment) => {
            if (investment.value == null || investment.value == 0) return sum;
            return sum + investment.value;
        }, 0);

        const sumStr = sum.toFixed(10);
        let decimalPlaces = sumStr.split('.')[1]?.replace(/0+$/, '').length || 0;

        if (decimalPlaces < 2) decimalPlaces = 2;

        return sum.toLocaleString('de-DE', {
            style: 'currency',
            currency: 'EUR',
            minimumFractionDigits: decimalPlaces,
            maximumFractionDigits: decimalPlaces,
        });
    };
    //get distributed value
    const getDistributedValue = (investments: any) => {
        const sum = investments.reduce((sum, investment) => {
            if (investment.distributedValue == null || investment.distributedValue == 0) return sum;
            return sum + investment.distributedValue;
        }, 0);

        const sumStr = sum.toFixed(10);
        let decimalPlaces = sumStr.split('.')[1]?.replace(/0+$/, '').length || 0;

        if (decimalPlaces < 2) decimalPlaces = 2;

        return sum.toLocaleString('de-DE', {
            style: 'currency',
            currency: 'EUR',
            minimumFractionDigits: decimalPlaces,
            maximumFractionDigits: decimalPlaces,
        });
    };
    //get distribution date
    const getDistributionDate = (investments: any) => {
        const sum = investments.reduce((sum, investment) => {
            if (investment.nextDistributionValue == null || investment.nextDistributionValue == 0) return sum;
            return sum + investment.nextDistributionValue;
        }, 0);

        const sumStr = sum.toFixed(10);
        let decimalPlaces = sumStr.split('.')[1]?.replace(/0+$/, '').length || 0;

        if (decimalPlaces < 2) decimalPlaces = 2;

        return sum.toLocaleString('de-DE', {
            style: 'currency',
            currency: 'EUR',
            minimumFractionDigits: decimalPlaces,
            maximumFractionDigits: decimalPlaces,
        });
    };
    //breadcrumbs
    const breadCrumbItems = ref([
        // {
        //     title: t('reservedArea.breadcrumbs.dashboard'),
        //     disabled: false,
        //     href: '/reserved-area/dashboard',
        // },
        {
            title: t('reservedArea.breadcrumbs.investments'),
            disabled: false,
            active: $router.currentRoute.value.name == 'Investment' ? true : false,
            href: '/reserved-area/investment',
        },
        {
            disabled: false,
            active: $router.currentRoute.value.name == 'InvestmentDetails' ? true : false,
        },
    ]);
</script>

<style scoped></style>
<style>
    .mdi-chevron-down {
        color: #131d3c !important;
    }
    .mdi-chevron-up {
        color: #131d3c !important;
    }
</style>
