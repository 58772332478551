export default {
    colors: {
        transparent: 'transparent',
        current: 'currentColor',
        primary: '#E5B969',
        'primary-light': '#FFF2DA',
        white: '#FFFFFF',
        'white-dark': '#F8FAFF',
        black: '#000000',
        secondary: '#131D3C',
        'secondary-light': '#46506F',
        'gray-default': '#131D3C33',
        'gray-dark': '#B0B0B0',
        'gray-darker': '#707070',
        'gray-light': '#CFD1D8',
        'gray-light-light': '#D0D2D8',
        'gray-lighter': '#f1f3f9',
        'gray-lighter-lighter': '#F8F8F8',
        'alert-success': '#4CAF50',
        'alert-error': '#F44336',
    },
} as any;