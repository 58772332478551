<template>
    <v-container
        v-if="isLoading == false"
        class="tw-flex tw-flex-col tw-gap-4 container">
        <div class="tw-flex tw-flex-col md:tw-justify-between md:tw-flex-row">
            <h1 class="tw-font-bold tw-text-secondary tw-text-center md:tw-text-left tw-text-3xl tw-underline tw-underline-offset-1 tw-decoration-primary tw-decoration-4 tw-mb-4">
                {{ $t('reservedArea.myPersonalData.myPersonalData') }}
            </h1>
            <!-- Upload documents / Request data change (>768px) -->
            <div
                v-if="user?.role?.name !== 'Admin'"
                class="tw-hidden md:tw-block tw-text-center md:tw-text-left tw-mt-4 md:tw-mt-0">
                <v-btn
                    variant="flat"
                    type="submit"
                    class="bg-gray-light text-black font-weight-bold !tw-text-base !tw-py-1.5 tw-mr-3.5 focus:!tw-bg-secondary focus:!tw-text-white"
                    max-height="none"
                    height="auto"
                    rounded="xs"
                    size="x-large"
                    @click="requestDataChange()">
                    {{ $t('reservedArea.myPersonalData.requestDataChange') }}
                </v-btn>
            </div>
        </div>
        <!-- success alert >768px -->
        <div class="tw-hidden md:tw-block">
            <div
                class="tw-flex tw-justify-end tw-z-10 !tw-mt-4 !tw-mb-2"
                v-show="success">
                <v-alert
                    class="!tw-justify-start"
                    dense
                    variant="tonal"
                    type="success"
                    >{{ $t('reservedArea.myPersonalData.successText') }}</v-alert
                >
                <div
                    class="tw-absolute tw-mr-2 tw-mt-1 tw-text-alert-success tw-cursor-pointer"
                    @click="reloadPage()">
                    X
                </div>
            </div>
        </div>
        <!-- person/company form -->
        <div class="tw-bg-white tw-rounded-lg">
            <p class="tw-font-bold tw-text-secondary tw-text-left tw-text-xl tw-ml-8 tw-mt-8">{{ $t('reservedArea.myPersonalData.companyData') }}</p>
            <v-form
                class="tw-ml-4 tw-mt-3"
                :disabled="isDisabled">
                <v-container class="">
                    <v-row>
                        <!-- name -->
                        <v-col
                            cols="12"
                            md="6">
                            <p
                                v-if="!isCompany"
                                class="tw-font-semibold tw-text-secondary tw-text-left tw-text-base tw-mb-3 tw-truncate">
                                {{ $t('reservedArea.myPersonalData.personName') }}
                            </p>
                            <p
                                v-if="isCompany"
                                class="tw-font-semibold tw-text-secondary tw-text-left tw-text-base tw-mb-3 tw-truncate">
                                {{ $t('reservedArea.myPersonalData.companyName') }}
                            </p>
                            <v-text-field
                                v-if="!isCompany"
                                v-model="name"
                                :label="$t('reservedArea.myPersonalData.personName')"
                                variant="outlined"></v-text-field>
                            <v-text-field
                                v-if="isCompany"
                                v-model="name"
                                :label="$t('reservedArea.myPersonalData.companyName')"
                                variant="outlined"></v-text-field>
                        </v-col>
                        <!-- address -->
                        <v-col
                            cols="12"
                            md="6">
                            <p class="tw-font-semibold tw-text-secondary tw-text-left tw-text-base tw-mb-3">{{ $t('reservedArea.myPersonalData.address') }}</p>
                            <v-text-field
                                v-model="address"
                                :label="$t('reservedArea.myPersonalData.address')"
                                variant="outlined"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <!-- nipc or nif -->
                        <v-col
                            cols="12"
                            xl="6">
                            <p
                                v-if="!isCompany"
                                class="tw-font-semibold tw-text-secondary tw-text-left tw-text-base tw-mb-3 tw-truncate">
                                {{ $t('reservedArea.myPersonalData.nif') }}
                            </p>
                            <p
                                v-if="isCompany"
                                class="tw-font-semibold tw-text-secondary tw-text-left tw-text-base tw-mb-3 tw-truncate">
                                {{ $t('reservedArea.myPersonalData.nipc') }}
                            </p>
                            <v-text-field
                                v-if="!isCompany"
                                v-model="nif"
                                :rules="[(v) => rules.nif(v, $t('reservedArea.myPersonalData.invalidNif'))]"
                                :label="$t('reservedArea.myPersonalData.nif')"
                                variant="outlined"></v-text-field>
                            <v-text-field
                                v-if="isCompany"
                                v-model="nipc"
                                :rules="[(v) => rules.nipc(v, $t('reservedArea.myPersonalData.invalidNipc'))]"
                                :label="$t('reservedArea.myPersonalData.nipc')"
                                variant="outlined"></v-text-field>
                        </v-col>
                        <!-- zip code -->
                        <v-col
                            cols="12"
                            xl="2">
                            <p class="tw-font-semibold tw-text-secondary tw-text-left tw-text-base tw-mb-3 tw-truncate">{{ $t('reservedArea.myPersonalData.zipCode') }}</p>
                            <v-text-field
                                v-model="zipCode"
                                :rules="[(v) => rules.zipCode(v, $t('reservedArea.myPersonalData.invalidZipCode'))]"
                                :label="$t('reservedArea.myPersonalData.zipCode')"
                                variant="outlined"></v-text-field>
                        </v-col>
                        <!-- location -->
                        <v-col
                            cols="12"
                            xl="">
                            <p class="tw-font-semibold tw-text-secondary tw-text-left tw-text-base tw-mb-3">{{ $t('reservedArea.myPersonalData.location') }}</p>
                            <v-text-field
                                v-model="location"
                                :label="$t('reservedArea.myPersonalData.location')"
                                variant="outlined"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <!-- district -->
                        <v-col
                            cols="12"
                            md="6">
                            <p class="tw-font-semibold tw-text-secondary tw-text-left tw-text-base tw-mb-3">{{ $t('reservedArea.myPersonalData.district') }}</p>
                            <v-text-field
                                v-model="district"
                                :label="$t('reservedArea.myPersonalData.district')"
                                variant="outlined"></v-text-field>
                        </v-col>
                        <!-- bank -->
                        <v-col
                            cols="12"
                            md="6">
                            <p class="tw-font-semibold tw-text-secondary tw-text-left tw-text-base tw-mb-3">{{ $t('reservedArea.myPersonalData.bank') }}</p>
                            <v-text-field
                                v-model="bank"
                                :label="$t('reservedArea.myPersonalData.bank')"
                                variant="outlined"></v-text-field>
                        </v-col>
                        <!-- CAE -->
                        <v-col
                            v-if="isCompany"
                            cols="12"
                            md="6">
                            <p class="tw-font-semibold tw-text-secondary tw-text-left tw-text-base tw-mb-3 tw-truncate">{{ $t('reservedArea.myPersonalData.mainCae') }}</p>
                            <v-text-field
                                v-model="cae"
                                :rules="[(v) => rules.cae(v, $t('reservedArea.myPersonalData.invalidCae'))]"
                                :label="$t('reservedArea.myPersonalData.mainCae')"
                                variant="outlined"></v-text-field>
                        </v-col>
                    </v-row>
                </v-container>
            </v-form>
        </div>
        <!-- contact person form -->
        <div class="tw-bg-white tw-rounded-lg tw-mt-9">
            <p class="tw-font-bold tw-text-secondary tw-text-left tw-text-xl tw-ml-8 tw-mt-8">{{ $t('reservedArea.myPersonalData.contactPerson') }}</p>
            <v-form
                class="tw-ml-4 tw-mt-3"
                :disabled="isDisabled"
                ref="contactPersonForm">
                <v-container class="">
                    <v-row>
                        <!-- contact person name -->
                        <v-col
                            cols="12"
                            md="6">
                            <p class="tw-font-semibold tw-text-secondary tw-text-left tw-text-base tw-mb-3">{{ $t('reservedArea.myPersonalData.name') }}</p>
                            <v-text-field
                                v-model="contactPersonName"
                                :label="$t('reservedArea.myPersonalData.name')"
                                variant="outlined"></v-text-field>
                        </v-col>
                        <!-- email -->
                        <v-col
                            cols="12"
                            md="6">
                            <p class="tw-font-semibold tw-text-secondary tw-text-left tw-text-base tw-mb-3">{{ $t('reservedArea.myPersonalData.email') }}</p>
                            <v-text-field
                                v-model="email"
                                :rules="[(v) => rules.email(v, $t('reservedArea.myPersonalData.invalidEmail'))]"
                                :label="$t('reservedArea.myPersonalData.email')"
                                variant="outlined"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <!-- role -->
                        <v-col
                            cols="12"
                            md="6">
                            <p class="tw-font-semibold tw-text-secondary tw-text-left tw-text-base tw-mb-3">{{ $t('reservedArea.myPersonalData.role') }}</p>
                            <v-text-field
                                v-model="role"
                                :label="$t('reservedArea.myPersonalData.role')"
                                variant="outlined"></v-text-field>
                        </v-col>
                        <!-- phone -->
                        <v-col
                            cols="12"
                            md="6">
                            <p class="tw-font-semibold tw-text-secondary tw-text-left tw-text-base tw-mb-3">{{ $t('reservedArea.myPersonalData.phone') }}</p>
                            <v-text-field
                                v-model="phone"
                                :rules="[(v) => rules.phone(v, $t('reservedArea.myPersonalData.invalidPhone'))]"
                                :label="$t('reservedArea.myPersonalData.phone')"
                                variant="outlined"></v-text-field>
                        </v-col>
                    </v-row>
                </v-container>
            </v-form>
        </div>
        <!-- upload documents form -->
        <div class="tw-bg-white tw-rounded-lg tw-mt-9">
            <p class="tw-font-bold tw-text-secondary tw-text-left tw-text-xl tw-ml-8 tw-mt-8">{{ $t('reservedArea.myPersonalData.uploadDocuments') }}</p>
            <v-form
                class="tw-ml-4 tw-mt-3"
                :disabled="isDisabled"
                ref="documentsForm">
                <v-container class="">
                    <v-row>
                        <v-col
                            cols="12"
                            md="12">
                            <v-file-input
                                v-model="files"
                                :label="$t('reservedArea.myPersonalData.uploadDocuments')"
                                outlined
                                multiple
                                @change="handleFilesUpload">
                            </v-file-input>
                        </v-col>
                    </v-row>
                </v-container>
            </v-form>
        </div>
        <!-- Upload documents / Request data change (<768px) -->
        <div
            v-if="user?.role?.name !== 'Admin'"
            class="tw-flex tw-flex-col tw-items-center md:tw-hidden tw-text-center tw-pb-8 tw-mt-2">
            <v-btn
                type="submit"
                class="bg-gray-light text-black font-weight-bold !tw-text-base !tw-py-1.5 focus:!tw-bg-secondary focus:!tw-text-white"
                max-height="none"
                height="auto"
                rounded="xs"
                variant="flat"
                size="x-large"
                @click="requestDataChange()">
                {{ $t('reservedArea.myPersonalData.requestDataChange') }}
            </v-btn>
        </div>
        <!-- success alert <768px -->
        <div class="tw-block md:tw-hidden">
            <div
                class="tw-flex tw-justify-end tw-z-10"
                v-show="success">
                <v-alert
                    class="!tw-justify-start"
                    dense
                    variant="tonal"
                    type="success"
                    >{{ $t('reservedArea.myPersonalData.successText') }}</v-alert
                >
                <div
                    class="tw-absolute tw-mr-2 tw-mt-1 tw-text-alert-success tw-cursor-pointer"
                    @click="reloadPage()">
                    X
                </div>
            </div>
        </div>
    </v-container>
    <div
        v-if="isLoading"
        class="tw-h-full tw-flex tw-items-center tw-justify-center">
        <v-progress-circular
            indeterminate
            color="primary"
            model-value="20"></v-progress-circular>
    </div>
</template>

<script setup lang="ts">
    import { ref } from 'vue';
    import { createDataChangeRequest } from '@/api/dataChangeRequest';
    import { getCustomerInfo } from '@/api/dbn';
    import { uploadFiles } from '@/api/upload';
    import { useRouter } from 'vue-router';
    import { useAuthStore } from '@/store/modules/auth';
    import { useI18n } from 'vue-i18n';
    import rules from '@/composables/rules';

    const { t: $t } = useI18n();

    const isDisabled = ref(false);
    const contactPersonForm = ref(null);

    const name = ref('');
    const address = ref('');
    const nipc = ref('');
    const nif = ref('');
    const zipCode = ref('');
    const location = ref('');
    const cae = ref('');
    const district = ref('');
    const bank = ref('');
    const contactPersonName = ref('');
    const email = ref('');
    const role = ref('');
    const phone = ref('');
    const files = ref([]);
    const success = ref(false);

    const authStore = useAuthStore();
    const user = authStore.getUser;
    const token = authStore.getToken;
    const isCompany = ref(null);

    const isLoading = ref(true);

    const fetchCustomerInfo = async () => {
        await getCustomerInfo(user?.dbnCode ? user?.dbnCode : user?.dbns[0]?.code, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((response) => {
                name.value = response.data.name;
                address.value = response.data.address;
                nipc.value = response.data.nipc;
                nif.value = response.data.nif;
                zipCode.value = response.data.postalCode;
                location.value = response.data.city;
                cae.value = response.data.cae;
                district.value = response.data.city;
                bank.value = response.data.bank;
                contactPersonName.value = response.data.contactPerson;
                email.value = response.data.email;
                role.value = response.data.contactPerson_role;
                phone.value = response.data.contactPerson_phone;
                files.value = response.data.files;

                isCompany.value = response.data.isCompany;
            })
            .finally(() => {
                isLoading.value = false;
            });
    };

    fetchCustomerInfo();

    const requestDataChange = () => {
        createDataChangeRequest(
            {
                data: {
                    user: {
                        id: authStore.getUser.id,
                    },
                    name: name.value ? name.value : null,
                    address: address.value ? address.value : null,
                    nipc: nipc.value ? nipc.value : null,
                    nif: nif.value ? nif.value : null,
                    zipCode: zipCode.value ? zipCode.value : null,
                    location: location.value ? location.value : null,
                    cae: cae.value ? cae.value : null,
                    district: district.value ? district.value : null,
                    bank: bank.value ? bank.value : null,
                    contactPerson_name: contactPersonName.value ? contactPersonName.value : null,
                    contactPerson_email: email.value ? email.value : null,
                    contactPerson_role: role.value ? role.value : null,
                    contactPerson_phone: phone.value ? phone.value : null,
                    files: files.value ? files.value : null,
                },
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            },
        ).then((response) => {
            success.value = true;
            isDisabled.value = true;
        });
    };

    const handleFilesUpload = () => {
        uploadFiles(files.value, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'multipart/form-data',
            },
        }).then((response) => {
            files.value = response.data;
        });
    };

    const router = useRouter();

    const reloadPage = () => {
        router.go(0);
    };
</script>

<style scoped></style>
<style>
    .v-input--density-default {
        --v-input-padding-top: 0.2rem;
    }
</style>
