import axios from '@/plugins/axios';
import qs from 'qs';

export function getNovelties(query = {}, config) {
    const queryString = qs.stringify(query);
    return axios.get('/novelties?' + queryString, config);
}
export function getNovelty(id, query = {}, config) {
    const queryString = qs.stringify(query);
    return axios.get('/novelties/' + id + '?' + queryString, config);
}