/**
 * plugins/vuetify.ts
 *
 * Framework documentation: https://vuetifyjs.com`
 */

// Styles
import '@mdi/font/css/materialdesignicons.css';
import 'vuetify/styles';

// Composables
import { createVuetify } from 'vuetify';
import theme from '../../theme';
import { VDataTableServer } from 'vuetify/labs/VDataTable';

// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
export default createVuetify({
    display: {
        mobileBreakpoint: 'sm',
        thresholds: {
            xs: 0,
            sm: 640,
            md: 768,
            lg: 1024,
            xl: 1280,
            xxl: 1536,
        },
    },
    theme: {
        themes: {
            light: {
                colors: theme.colors,
            },
        },
    },
    defaults: {
        VDataTableServer: {
            itemsPerPageOptions: [
                { value: 5, title: '5' },
                { value: 10, title: '10' },
                { value: 15, title: '15' },
                { value: 20, title: '20' },
            ],
        },
    },
});
