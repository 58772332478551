<template>
    <v-container
        class="container"
        v-if="loaded == true">
        <div class="tw-flex tw-flex-col md:tw-justify-between md:tw-flex-row">
            <h1 class="tw-font-bold tw-text-secondary tw-text-center md:tw-text-left tw-text-3xl tw-underline tw-underline-offset-1 tw-decoration-primary tw-decoration-4 tw-mb-4">
                {{ $t('reservedArea.novelties.novelties') }}
            </h1>
        </div>
        <!-- Breadcrumbs -->
        <div class="tw-text-lg tw-text-secondary tw-hidden xl:tw-block">
            <v-breadcrumbs
                :items="breadCrumbItems"
                active-class="!tw-font-semibold"
                class="!tw-pl-0">
                <template v-slot:divider>
                    <v-icon icon="mdi-chevron-right"></v-icon>
                </template>
            </v-breadcrumbs>
        </div>
        <!-- > 768px -->
        <div
            v-if="loaded"
            class="tw-bg-white tw-rounded-lg tw-mt-4 tw-p-4 !tw-hidden md:!tw-block">
            <v-row>
                <v-col
                    xs="12"
                    sm="12"
                    md="12"
                    lg="12"
                    xl="5">
                    <div class="tw-relative">
                        <div class="tw-absolute tw-bg-white tw-opacity-60 tw-rounded tw-top-3.5 tw-right-3.5 tw-p-0.5 tw-z-10">
                            <p class="tw-font-semibold tw-text-secondary tw-text-center tw-text-xs">{{ novelty.createdAt }} | BlueCrow</p>
                        </div>
                        <v-img
                            v-if="novelty.picture?.url"
                            cover
                            height="40vh"
                            :src="`${mediaUrl}${novelty.picture.url}`"
                            class="tw-rounded-lg"></v-img>
                        <v-img
                            v-if="!novelty.picture?.url"
                            contain
                            height="40vh"
                            src="/images/blue_crow_crow_only.jpg"
                            class="tw-rounded-lg"></v-img>
                    </div>
                </v-col>
                <v-col class="tw-flex">
                    <div class="tw-flex tw-flex-col tw-justify-between">
                        <div>
                            <p class="tw-font-semibold tw-text-secondary tw-text-left tw-text-4xl tw-mb-4">{{ novelty.title }}</p>
                            <p class="tw-text-secondary tw-text-left tw-text-lg">
                                <span
                                    class="novelty-description-span"
                                    v-html="novelty.description"></span>
                            </p>
                        </div>
                        <div class="tw-flex tw-gap-x-4 tw-mt-4">
                            <div
                                class="tw-flex tw-items-center tw-justify-center tw-h-6 tw-w-24 tw-bg-primary-light tw-rounded tw-px-5 tw-py-4"
                                v-for="category in categories"
                                :key="category">
                                <p class="tw-text-secondary tw-text-center tw-text-xs">{{ category.name }}</p>
                            </div>
                        </div>
                    </div>
                </v-col>
            </v-row>
        </div>
        <!-- <= 768px -->
        <div
            v-if="loaded"
            class="tw-p-4 !tw-block md:!tw-hidden">
            <v-col
                class="!tw-block md:!tw-hidden"
                cols=""
                xs="12"
                sm="12"
                md="6"
                lg="4"
                xl="3">
                <v-card
                    variant="flat"
                    rounded="lg">
                    <div class="tw-absolute tw-bg-white tw-opacity-60 tw-rounded tw-top-3.5 tw-right-3.5 tw-p-0.5 tw-z-10">
                        <p class="tw-font-semibold tw-text-secondary tw-text-center tw-text-xs">{{ novelty.createdAt }} | BlueCrow</p>
                    </div>
                    <v-img
                        v-if="novelty.picture?.url"
                        cover
                        :src="`${mediaUrl}${novelty.picture.url}`"
                        class="!tw-max-h-56 !tw-w-full tw-rounded-lg"></v-img>
                    <v-img
                        v-if="!novelty.picture?.url"
                        contain
                        src="/images/blue_crow_crow_only.jpg"
                        class="!tw-max-h-56 !tw-w-full tw-rounded-lg"></v-img>
                    <v-card-text class="!tw-text-left !tw-font-bold !tw-text-4xl tw-text-secondary !tw-pb-0">{{ novelty.title }}</v-card-text>
                    <v-card-text class="!tw-pb-0">
                        <p class="tw-text-secondary tw-text-left tw-text-lg">
                            <span
                                class="novelty-description-span"
                                v-html="novelty.description"></span>
                        </p>
                    </v-card-text>
                    <v-card-text>
                        <div class="tw-flex xs:tw-gap-x-4 tw-flex-col xs:tw-flex-row tw-gap-y-4 xs:tw-gap-y-0">
                            <div
                                class="tw-flex tw-items-center tw-justify-center tw-h-6 tw-w-32 xs:tw-w-28 tw-bg-primary-light tw-rounded tw-px-5 tw-py-4"
                                v-for="category in categories"
                                :key="category">
                                <p class="tw-text-secondary tw-text-center tw-text-xs">{{ category.name }}</p>
                            </div>
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
        </div>
    </v-container>
    <div
        v-if="!loaded"
        class="tw-h-full tw-flex tw-items-center tw-justify-center">
        <v-progress-circular
            indeterminate
            color="primary"
            model-value="20"></v-progress-circular>
    </div>
</template>

<script setup lang="ts">
    import { ref, watch, computed } from 'vue';
    import { useI18n } from 'vue-i18n';
    import { getNovelty } from '@/api/novelty';
    import { useLanguageStore } from '@/store/modules/language';
    import { useAuthStore } from '@/store/modules/auth';
    import { useRouter } from 'vue-router';
    import { useRoute } from 'vue-router';

    const { t } = useI18n();

    const $router = useRouter();

    const loaded = ref(false);

    const mediaUrl = ref(import.meta.env.VITE_MEDIA_URL);

    //language store
    const languageStore = useLanguageStore();
    const language = computed(() => languageStore.getLanguage);

    //auth store
    const authStore = useAuthStore();
    const token = authStore.getToken;

    const novelty = ref([]);
    const categories = ref([]);

    //get novelty id from url
    const $route = useRoute();
    let noveltyId = $route.params.id;

    //change novelty language
    watch(language, () => {
        if (language.value == 'pt') {
            if (noveltyId == 1) {
                noveltyId = 1;
            } else {
                noveltyId = parseInt(noveltyId) - 1;
            }
        }
        if (language.value == 'en') {
            if (noveltyId == 2) {
                noveltyId = 2;
            } else {
                noveltyId = parseInt(noveltyId) + 1;
            }
        }
        //change url id without reloading page
        $router.push({ name: 'NoveltyDetails', params: { id: noveltyId } });

        fetchNovelty();
    });

    //get novelty
    const fetchNovelty = () =>
        getNovelty(
            noveltyId,
            {
                populate: 'picture, categories',
                locale: language.value == 'pt' ? 'pt' : 'en',
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            },
        ).then((response) => {
            novelty.value = response.data.data;
            categories.value = response.data.data.categories;
            const date = new Date(novelty.value.createdAt);
            const options = { day: 'numeric', month: 'long', year: 'numeric' };
            novelty.value.createdAt = date.toLocaleDateString(language.value, options);
            loaded.value = true;
        });
    fetchNovelty();

    watch(novelty, () => {
        if (novelty.value.title) {
            breadCrumbItems.value[1].title = novelty.value.title;
            breadCrumbItems.value[1].href = `/reserved-area/novelty/${novelty.value.id}`;
        }
    });

    //breadcrumbs
    const breadCrumbItems = ref([
        // {
        //     title: t('reservedArea.breadcrumbs.dashboard'),
        //     disabled: false,
        //     href: '/reserved-area/dashboard',
        // },
        {
            title: t('reservedArea.breadcrumbs.novelties'),
            disabled: false,
            active: false,
            href: '/reserved-area/novelty',
        },
        {
            disabled: false,
            active: $router.currentRoute.value.name == 'NoveltyDetails' ? true : false,
        },
    ]);
</script>

<style>
    .novelty-description-span a {
        font-weight: 600;
    }
    .novelty-description-span p {
        margin-bottom: 16px;
    }
</style>
