import axios from '@/plugins/axios';
import qs from 'qs';

export function getDbns(query = {}, config) {
    const queryString = qs.stringify(query);
    return axios.get('/dbns?' + queryString, config);
}
export function getCustomerId(query = {}, config) {
    const queryString = qs.stringify(query);
    return axios.get('/dbns?' + queryString, config);
}
//functions for dbn funds
export function getFundDetail(language, fundId, config) {
    return axios.get('/dbns/funds/detail/' + language + '/' + fundId, config);
}
export function getAllFunds(language, config) {
    return axios.get('/dbns/funds/all/' + language, config);
}
export function getPublicDocuments(language, fundId, config) {
    return axios.get('/dbns/funds/public-documents/' + language + '/' + fundId, config);
}
export function getInvestorDocuments(language, fundId, config) {
    return axios.get('/dbns/funds/investor-documents/' + language + '/' + fundId, config);
}
export function getDocument(documentId, config) {
    return axios.get('/dbns/funds/document/' + documentId, config);
}
//functions for dbn customerInfo
export function getCustomerInfo(costumerId, config) {
    return axios.get('/dbns/customer-info/get/' + costumerId, config);
}
export function getCustomerDocuments(language, costumerId, config) {
    return axios.get('/dbns/customer-info/documents/' + language + '/' + costumerId, config);
}
export function getCustomerFunds(language, customerId, config) {
    return axios.get('/dbns/customer-info/funds/' + language + '/' + customerId, config);
}
export function getCustomerMovements(language, customerId, fundId, category, config) {
    return axios.get('/dbns/customer-info/movements/' + language + '/' + customerId + '/' + fundId + '/' + category, config);
}
export function findCustomerInfo(nif, email, config) {
    return axios.get('/dbns/customer-info/find/' + nif + '/' + email, config);
}
