<template>
    <v-footer class="!tw-bg-secondary text-center d-flex flex-column !tw-px-0">
        <div>
            <v-img src="/images/bluecrow-logo-white-retina.png" class="tw-my-8" width="200" height="100"></v-img>
        </div>
        <section class="tw-flex tw-flex-row tw-gap-x-10 tw-flex-wrap tw-justify-center container-md">
            <a href="" class="tw-text-base md:tw-text-lg tw-mb-4 tw-text-white">{{ $t('commercial.footer.featuredSectos') }}</a>
            <a href="" class="tw-text-base md:tw-text-lg tw-mb-4 tw-text-white">{{ $t('commercial.footer.news') }}</a>
            <a href="" class="tw-text-base md:tw-text-lg tw-mb-4 tw-text-white">{{ $t('commercial.footer.howToInvest') }}</a>
            <a href="" class="tw-text-base md:tw-text-lg tw-mb-4 tw-text-white">{{ $t('commercial.footer.legalInformation') }}</a>
            <a href="" class="tw-text-base md:tw-text-lg tw-mb-4 tw-text-white">{{ $t('commercial.footer.security') }}</a>
            <a href="" class="tw-text-base md:tw-text-lg tw-mb-4 tw-text-white">{{ $t('commercial.footer.termsOfService') }}</a>
            <a href="" class="tw-text-base md:tw-text-lg tw-mb-4 tw-text-white">{{ $t('commercial.footer.riskSummary') }}</a>
        </section>
        <div class="tw-border-b tw-border-gray-darker tw-w-full"></div>
        <section class="!tw-flex !tw-flex-row !tw-justify-between !tw-items-center tw-gap-x-12">
            <div class="tw-text-center xl:tw-text-left tw-text-sm md:tw-text-base tw-text-white tw-py-6 tw-w-9/12 !tw-flex-grow">
                © 2023 BlueCrow | {{ $t('commercial.footer.allRightsReserved') }}
            </div>
            <!-- > 640px -->
            <div class="tw-hidden sm:tw-block">
                <div class="!tw-flex tw-gap-x-4">
                    <span class="fi fi-pt hover:tw-cursor-pointer" @click="setLanguage('pt')"></span>
                    <span class="fi fi-gb hover:tw-cursor-pointer" @click="setLanguage('en')"></span>
                </div>
            </div>
        </section>
        <section>
            <!-- < 640px -->
            <div class="tw-block sm:tw-hidden">
                <div class="tw-flex tw-gap-x-4">
                    <span class="fi fi-pt hover:tw-cursor-pointer" @click="setLanguage('pt')"></span>
                    <span class="fi fi-gb hover:tw-cursor-pointer" @click="setLanguage('en')"></span>
                </div>
            </div>
        </section>
    </v-footer>
</template>

<script setup lang="ts">
    import { useI18n } from 'vue-i18n';
    import { useLanguageStore } from '@/store/modules/language';

    const i18n = useI18n();
    const store = useLanguageStore();

    const setLanguage = (language: string) => {
        i18n.locale.value = language;
        store.setLanguage(language);
    };

    setLanguage(store.language);

</script>

<style scoped></style>
