import axios from '@/plugins/axios';
import qs from 'qs';

export function getUsers(query = {}, config) {
    const queryString = qs.stringify(query);
    return axios.get('/users?' + queryString, config);
}
export function me(query = {}, config) {
    const queryString = qs.stringify(query);
    return axios.get(`/users/me?` + queryString, config);
}
export function updateUser(id, data, config) {
    return axios.put(`/users/${id}`, data, config);
}