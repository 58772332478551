// Composables
import { createRouter, createWebHistory } from 'vue-router';
import { useAuthStore } from '@/store/modules/auth';

import CommercialHome from '@/views/Commercial/Home.vue';
import ReservedAreaHome from '@/views/ReservedArea/Home.vue';
import AboutUs from '@/views/Commercial/AboutUs.vue';
// import Invest from '@/views/Commercial/Invest.vue';
// import Sell from '@/views/Commercial/Sell.vue';
// import Raise from '@/views/Commercial/Raise.vue';
import Auth from '@/views/Auth/Auth.vue';
import Login from '@/views/Auth/Login.vue';
import Register from '@/views/Auth/Register.vue';
import ForgotPassword from '@/views/Auth/ForgotPassword.vue';
import ResetPassword from '@/views/Auth/ResetPassword.vue';
// import Dashboard from '@/views/ReservedArea/Dashboard.vue';
import Investment from '@/views/ReservedArea/Investments/Investment.vue';
import InvestmentDetails from '@/views/ReservedArea/Investments/InvestmentDetails.vue';
import Novelty from '@/views/ReservedArea/Novelties/Novelty.vue';
import NoveltyDetails from '@/views/ReservedArea/Novelties/NoveltyDetails.vue';
import HelpfulDocument from '@/views/ReservedArea/HelpfulDocument.vue';
import TalkToUs from '@/views/ReservedArea/TalkToUs.vue';
import Website from '@/views/ReservedArea/Website.vue';
import Users from '@/views/ReservedArea/Users.vue';
import UsersAdmin from '@/views/ReservedArea/UsersAdmin.vue';
import AddUserRequest from '@/views/ReservedArea/AddUserRequest.vue';
import ExportData from '@/views/ReservedArea/ExportData.vue';
import Administration from '@/views/ReservedArea/Administration.vue';
import MyPersonalData from '@/views/ReservedArea/MyPersonalData.vue';

const routes = [
    {
        path: '/',
        component: CommercialHome,
        name: 'CommercialHome',
        children: [
            {
                path: '',
                component: AboutUs,
                name: 'AboutUs',
            },
            // {
            //     path: 'invest',
            //     component: Invest,
            //     name: 'Invest',
            // },
            // {
            //     path: 'sell',
            //     component: Sell,
            //     name: 'Sell',
            // },
            // {
            //     path: 'raise',
            //     component: Raise,
            //     name: 'Raise',
            // },
        ],
    },
    {
        path: '/reserved-area',
        component: ReservedAreaHome,
        name: 'ReservedAreaHome',
        children: [
            // {
            //     path: 'dashboard',
            //     component: Dashboard,
            //     name: 'Dashboard',
            // },
            {
                path: 'investment',
                component: Investment,
                name: 'Investment',
            },
            {
                path: 'investment/:id',
                component: InvestmentDetails,
                name: 'InvestmentDetails',
            },
            {
                path: 'novelty',
                component: Novelty,
                name: 'Novelty',
            },
            {
                path: 'novelty/:id',
                component: NoveltyDetails,
                name: 'NoveltyDetails',
            },
            {
                path: 'helpful-document',
                component: HelpfulDocument,
                name: 'HelpfulDocument',
            },
            {
                path: 'talk-to-us',
                component: TalkToUs,
                name: 'TalkToUs',
            },
            {
                path: 'website',
                component: Website,
                name: 'Website',
            },
            {
                path: 'users',
                component: Users,
                name: 'Users',
            },
            {
                path: 'users-admin',
                component: UsersAdmin,
                name: 'UsersAdmin',
            },
            {
                path: 'users/add-user-request',
                component: AddUserRequest,
                name: 'AddUserRequest',
            },
            {
                path: 'export-data',
                component: ExportData,
                name: 'ExportData',
            },
            {
                path: 'administration',
                component: Administration,
                name: 'Administration',
            },
            {
                path: 'my-personal-data',
                component: MyPersonalData,
                name: 'MyPersonalData',
            },
        ],
    },
    {
        path: '/auth',
        component: Auth,
        name: 'Auth',
        children: [
            {
                path: 'login',
                component: Login,
                name: 'Auth-Login',
            },
            {
                path: 'register',
                component: Register,
                name: 'Auth-Register',
            },
            {
                path: 'forgot-password',
                component: ForgotPassword,
                name: 'Auth-ForgotPassword',
            },
            {
                path: 'reset-password',
                component: ResetPassword,
                name: 'Auth-ResetPassword',
            },
        ],
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach((to, from, next) => {
    const authStore = useAuthStore();
    const isAuthenticated = authStore.getIsAuthenticated;
    const user = authStore.getUser;
    if (to.path.includes('/reserved-area')) {
        if (isAuthenticated) {
            if (to.path.includes('/users-admin')) {
                if (user?.role?.name == 'Authenticated' || user?.role?.name == 'Manager') {
                    next({ name: 'Investment' });
                }
                else next();
            }
            else if (to.path.includes('/users')) {
                if (user?.role?.name == 'Manager') {
                    next({ name: 'Investment' });
                }
                if (user?.role?.name == 'Admin') {
                    next({ name: 'UsersAdmin' });
                }
                else next();
            }
            else next();
        } else {
            next({ name: 'Auth-Login' });
        }
    }
    else if (to.path.includes('/auth')) {
        if (isAuthenticated) {
            if (user?.role?.name === 'Admin') {
                next({ name: 'UsersAdmin' });
            }
            else next({ name: 'Investment' });
        } else {
            next();
        }
    }
    else {
        next();
    }
});

export default router;
