import vue from '@vitejs/plugin-vue';
<template>
    <!-- Desktop -->
    <v-navigation-drawer
        class="custom-active-item desktop-navigation-drawer d-none d-lg-flex !tw-bg-secondary !tw-w-[20.75rem]"
        v-model="desktopDrawer"
        border="false"
        permanent>
        <div class="tw-flex tw-flex-col tw-justify-between tw-h-full">
            <div class="tw-flex tw-justify-center tw-items-center 2xl:tw-my-14 tw-my-8">
                <v-img
                    max-width="150"
                    src="/images/bluecrow-logo-white-retina.png"></v-img>
            </div>
            <v-list
                nav
                class="!tw-bg-secondary !tw-text-white tw-text-base tw-text-left !tw-overflow-auto !tw-px-0"
                bg-color="tw-text-secondary">
                <!-- Dashboard -->
                <!-- <v-list-item link :to="{ name: 'Dashboard' }" class="hover:tw-bg-primary active:tw-bg-primary hover:tw-rounded-r-full active:tw-rounded-r-full hover:tw-w-11/12 active:tw-w-11/12">
                    <div class="tw-flex tw-ml-12">
                        <v-list-item-icon>
                            <v-icon>
                                <v-img src="/images/ReservedArea/icons/dashboard.svg"></v-img>
                            </v-icon>
                        </v-list-item-icon>
                        <p class="tw-ml-6">{{ $t('reservedArea.sidebar.dashboard') }}</p>
                    </div>
                </v-list-item> -->
                <!-- Investments -->
                <v-list-item
                    v-if="user?.role?.name == 'Manager' || (user?.role?.name == 'Admin' && user?.dbnCode !== null) || user?.role?.name == 'Authenticated'"
                    link
                    :to="{ name: 'Investment' }"
                    class="hover:tw-bg-primary active:tw-bg-primary hover:tw-rounded-r-full active:tw-rounded-r-full hover:tw-w-11/12 active:tw-w-11/12">
                    <div class="tw-flex tw-ml-12">
                        <v-list-item-icon>
                            <v-icon>
                                <v-img src="/images/ReservedArea/icons/investment.svg"></v-img>
                            </v-icon>
                        </v-list-item-icon>
                        <p class="tw-ml-6">{{ $t('reservedArea.sidebar.investments') }}</p>
                    </div>
                </v-list-item>
                <!-- Novelties -->
                <v-list-item
                    link
                    :to="{ name: 'Novelty' }"
                    class="hover:tw-bg-primary active:tw-bg-primary hover:tw-rounded-r-full active:tw-rounded-r-full hover:tw-w-11/12 active:tw-w-11/12">
                    <div class="tw-flex tw-ml-12">
                        <v-list-item-icon>
                            <v-icon>
                                <v-img src="/images/ReservedArea/icons/novelty.svg"></v-img>
                            </v-icon>
                        </v-list-item-icon>
                        <p class="tw-ml-6">{{ $t('reservedArea.sidebar.novelties') }}</p>
                    </div>
                </v-list-item>
                <!-- Helpful Documents -->
                <v-list-item
                    v-if="user?.role?.name == 'Manager' || (user?.role?.name == 'Admin' && user?.dbnCode !== null) || user?.role?.name == 'Authenticated'"
                    link
                    :to="{ name: 'HelpfulDocument' }"
                    class="hover:tw-bg-primary active:tw-bg-primary hover:tw-rounded-r-full active:tw-rounded-r-full hover:tw-w-11/12 active:tw-w-11/12">
                    <div class="tw-flex tw-ml-12">
                        <v-list-item-icon>
                            <v-icon>
                                <v-img src="/images/ReservedArea/icons/helpful-document.svg"></v-img>
                            </v-icon>
                        </v-list-item-icon>
                        <p class="tw-ml-6">{{ $t('reservedArea.sidebar.helpfulDocuments') }}</p>
                    </div>
                </v-list-item>
                <!-- Talk to Us -->
                <v-list-item
                    link
                    :to="{ name: 'TalkToUs' }"
                    class="hover:tw-bg-primary active:tw-bg-primary hover:tw-rounded-r-full active:tw-rounded-r-full hover:tw-w-11/12 active:tw-w-11/12">
                    <div class="tw-flex tw-ml-12">
                        <v-list-item-icon>
                            <v-icon>
                                <v-img src="/images/ReservedArea/icons/talk-to-us.svg"></v-img>
                            </v-icon>
                        </v-list-item-icon>
                        <p class="tw-ml-6">{{ $t('reservedArea.sidebar.talkToUs') }}</p>
                    </div>
                </v-list-item>
                <!-- Website -->
                <v-list-item
                    v-if="user?.role?.name == 'Manager' || user?.role?.name == 'Authenticated'"
                    link
                    target="_blank"
                    href="https://bluecrow.iotechpis.com/"
                    class="hover:tw-bg-primary active:tw-bg-primary hover:tw-rounded-r-full active:tw-rounded-r-full hover:tw-w-11/12 active:tw-w-11/12">
                    <div class="tw-flex tw-ml-12">
                        <v-list-item-icon>
                            <v-icon>
                                <v-img src="/images/ReservedArea/icons/website-bluecrow.svg"></v-img>
                            </v-icon>
                        </v-list-item-icon>
                        <p class="tw-ml-6">{{ $t('reservedArea.sidebar.website') }}</p>
                    </div>
                </v-list-item>
                <!-- Users -->
                <v-list-item
                    v-if="user?.role?.name !== 'Manager'"
                    link
                    :to="user?.role?.name == 'Authenticated' ? { name: 'Users' } : { name: 'UsersAdmin' }"
                    class="hover:tw-bg-primary active:tw-bg-primary hover:tw-rounded-r-full active:tw-rounded-r-full hover:tw-w-11/12 active:tw-w-11/12">
                    <div class="tw-flex tw-ml-12">
                        <v-list-item-icon>
                            <v-icon>
                                <v-img src="/images/ReservedArea/icons/user.svg"></v-img>
                            </v-icon>
                        </v-list-item-icon>
                        <p class="tw-ml-6">{{ $t('reservedArea.sidebar.users') }}</p>
                    </div>
                </v-list-item>
                <!-- Companies -->
                <v-list-group value="">
                    <template v-slot:activator="{ props }">
                        <v-list-item
                            v-bind="props"
                            v-if="user?.role?.name == 'Manager' || (user?.role?.name == 'Admin' && user?.dbnCode !== null) || user?.role?.name == 'Authenticated'"
                            link
                            class="hover:tw-bg-primary active:tw-bg-primary hover:tw-rounded-r-full active:tw-rounded-r-full hover:tw-w-11/12 active:tw-w-11/12">
                            <div class="tw-flex tw-ml-12">
                                <v-list-item-icon>
                                    <v-icon> mdi-domain </v-icon>
                                </v-list-item-icon>
                                <p class="tw-ml-6">{{ $t('reservedArea.sidebar.companies') }}</p>
                            </div>
                        </v-list-item>
                    </template>
                    <v-list-item
                        v-for="(dbn, i) in user?.dbns"
                        :key="i"
                        @click="setDbnCode(dbn?.code)">
                        <div class="tw-flex tw-ml-2">
                            <p
                                v-if="!user?.dbnCode"
                                class="tw-ml-6"
                                :class="user?.dbns[0].code == dbn?.code ? 'tw-text-primary' : ''">
                                {{ dbn?.name }}
                            </p>
                            <p
                                v-if="user?.dbnCode"
                                class="tw-ml-6"
                                :class="user?.dbnCode == dbn?.code ? 'tw-text-primary' : ''">
                                {{ dbn?.name }}
                            </p>
                        </div>
                    </v-list-item>
                </v-list-group>
            </v-list>
            <div class="tw-flex tw-justify-center tw-items-center tw-py-3 2xl:tw-py-7 tw-mt-auto tw-gap-x-8">
                <div class="tw-flex tw-justify-center tw-items-center">
                    <div>
                        <router-link :to="{ name: 'MyPersonalData' }">
                            <v-img
                                aspect-ratio="1"
                                :src="getPicture()"
                                cover
                                class="tw-rounded-full tw-w-12 tw-h-12 2xl:tw-w-16 2xl:tw-h-16"></v-img>
                        </router-link>
                    </div>
                    <router-link :to="{ name: 'MyPersonalData' }">
                        <p class="tw-text-lg tw-font-semibold tw-text-white tw-text-left tw-mx-5 tw-max-w-[8.125rem] tw-overflow-hidden tw-overflow-ellipsis">{{ user.username }}</p>
                    </router-link>
                </div>
                <div class="tw-flex tw-text-center tw-justify-center tw-w-14 tw-mt-1 tw-gap-x-4">
                    <span
                        class="fi fi-pt hover:tw-cursor-pointer"
                        @click="setLanguage('pt')"></span>
                    <span
                        class="fi fi-gb hover:tw-cursor-pointer"
                        @click="setLanguage('en')"></span>
                </div>
            </div>
            <v-list
                class="!tw-bg-secondary-light !tw-text-white tw-text-base tw-text-left"
                bg-color="tw-text-secondary-light">
                <v-list-item @click="logout()">
                    <div class="tw-flex tw-ml-12">
                        <v-list-item-icon>
                            <v-icon>
                                <v-img src="/images/ReservedArea/icons/logout.svg"></v-img>
                            </v-icon>
                        </v-list-item-icon>
                        <p class="tw-ml-6">{{ $t('reservedArea.sidebar.logout') }}</p>
                    </div>
                </v-list-item>
            </v-list>
        </div>
    </v-navigation-drawer>
    <!-- Mobile -->
    <v-navigation-drawer
        :class="{ 'nav-opened': !rail, 'nav-closed': rail }"
        class="d-none d-lg-none d-flex custom-active-item"
        v-model="mobileDrawer"
        :rail="rail"
        permanent
        @click="rail = false">
        <v-app-bar-nav-icon
            v-if="rail"
            class="tw-mr-6 tw-text-center !tw-text-secondary"
            variant="text"
            @click.stop="rail = !rail"></v-app-bar-nav-icon>
        <div class="tw-flex tw-flex-col tw-justify-between tw-h-full">
            <div class="tw-flex tw-justify-center tw-items-center 2xl:tw-my-14 tw-my-8">
                <v-img
                    max-width="150"
                    src="/images/bluecrow-logo-white-retina.png"></v-img>
                <v-icon
                    @click.stop="rail = !rail"
                    class="!tw-text-white tw-pl-16">
                    mdi-close
                </v-icon>
            </div>
            <v-list
                nav
                class="!tw-bg-secondary !tw-text-white tw-text-base tw-text-left !tw-overflow-auto"
                bg-color="tw-text-secondary">
                <!-- Dashboard -->
                <!-- <v-list-item link :to="{ name: 'Dashboard' }" class="hover:tw-bg-primary active:tw-bg-primary hover:tw-rounded-r-full active:tw-rounded-r-full hover:tw-w-11/12 active:tw-w-11/12">
                    <div class="tw-flex tw-ml-12">
                        <v-list-item-icon>
                            <v-icon> <v-img src="/images/ReservedArea/icons/dashboard.svg"></v-img></v-icon>
                        </v-list-item-icon>
                        <p class="tw-ml-6">{{ $t('reservedArea.sidebar.dashboard') }}</p>
                    </div>
                </v-list-item> -->
                <!-- Investments -->
                <v-list-item
                    @click.stop="rail = !rail"
                    v-if="user?.role?.name == 'Manager' || (user?.role?.name == 'Admin' && user?.dbnCode !== null) || user?.role?.name == 'Authenticated'"
                    link
                    :to="{ name: 'Investment' }"
                    class="hover:tw-bg-primary active:tw-bg-primary hover:tw-rounded-r-full active:tw-rounded-r-full hover:tw-w-11/12 active:tw-w-11/12">
                    <div class="tw-flex tw-ml-12">
                        <v-list-item-icon>
                            <v-icon>
                                <v-img src="/images/ReservedArea/icons/investment.svg"></v-img>
                            </v-icon>
                        </v-list-item-icon>
                        <p class="tw-ml-6">{{ $t('reservedArea.sidebar.investments') }}</p>
                    </div>
                </v-list-item>
                <!-- Novelties -->
                <v-list-item
                    @click.stop="rail = !rail"
                    link
                    :to="{ name: 'Novelty' }"
                    class="hover:tw-bg-primary active:tw-bg-primary hover:tw-rounded-r-full active:tw-rounded-r-full hover:tw-w-11/12 active:tw-w-11/12">
                    <div class="tw-flex tw-ml-12">
                        <v-list-item-icon>
                            <v-icon><v-img src="/images/ReservedArea/icons/novelty.svg"></v-img></v-icon>
                        </v-list-item-icon>
                        <p class="tw-ml-6">{{ $t('reservedArea.sidebar.novelties') }}</p>
                    </div>
                </v-list-item>
                <!-- Helpful Documents -->
                <v-list-item
                    v-if="user?.role?.name == 'Manager' || (user?.role?.name == 'Admin' && user?.dbnCode !== null) || user?.role?.name == 'Authenticated'"
                    @click.stop="rail = !rail"
                    link
                    :to="{ name: 'HelpfulDocument' }"
                    class="hover:tw-bg-primary active:tw-bg-primary hover:tw-rounded-r-full active:tw-rounded-r-full hover:tw-w-11/12 active:tw-w-11/12">
                    <div class="tw-flex tw-ml-12">
                        <v-list-item-icon>
                            <v-icon><v-img src="/images/ReservedArea/icons/helpful-document.svg"></v-img></v-icon>
                        </v-list-item-icon>
                        <p class="tw-ml-6">{{ $t('reservedArea.sidebar.helpfulDocuments') }}</p>
                    </div>
                </v-list-item>
                <!-- Talk to Us -->
                <v-list-item
                    @click.stop="rail = !rail"
                    link
                    :to="{ name: 'TalkToUs' }"
                    class="hover:tw-bg-primary active:tw-bg-primary hover:tw-rounded-r-full active:tw-rounded-r-full hover:tw-w-11/12 active:tw-w-11/12">
                    <div class="tw-flex tw-ml-12">
                        <v-list-item-icon>
                            <v-icon><v-img src="/images/ReservedArea/icons/talk-to-us.svg"></v-img></v-icon>
                        </v-list-item-icon>
                        <p class="tw-ml-6">{{ $t('reservedArea.sidebar.talkToUs') }}</p>
                    </div>
                </v-list-item>
                <!-- Website -->
                <v-list-item
                    v-if="user?.role?.name == 'Manager' || user?.role?.name == 'Authenticated'"
                    @click.stop="rail = !rail"
                    link
                    target="_blank"
                    href="https://bluecrow.iotechpis.com/"
                    class="hover:tw-bg-primary active:tw-bg-primary hover:tw-rounded-r-full active:tw-rounded-r-full hover:tw-w-11/12 active:tw-w-11/12">
                    <div class="tw-flex tw-ml-12">
                        <v-list-item-icon>
                            <v-icon><v-img src="/images/ReservedArea/icons/website-bluecrow.svg"></v-img></v-icon>
                        </v-list-item-icon>
                        <p class="tw-ml-6">{{ $t('reservedArea.sidebar.website') }}</p>
                    </div>
                </v-list-item>
                <!-- Users -->
                <v-list-item
                    v-if="user?.role?.name !== 'Manager'"
                    @click.stop="rail = !rail"
                    link
                    :to="user?.role?.name === 'Authenticated' ? { name: 'Users' } : { name: 'UsersAdmin' }"
                    class="hover:tw-bg-primary active:tw-bg-primary hover:tw-rounded-r-full active:tw-rounded-r-full hover:tw-w-11/12 active:tw-w-11/12">
                    <div class="tw-flex tw-ml-12">
                        <v-list-item-icon>
                            <v-icon><v-img src="/images/ReservedArea/icons/user.svg"></v-img></v-icon>
                        </v-list-item-icon>
                        <p class="tw-ml-6">{{ $t('reservedArea.sidebar.users') }}</p>
                    </div>
                </v-list-item>
                <!-- Companies -->
                <v-list-group value="">
                    <template v-slot:activator="{ props }">
                        <v-list-item
                            v-bind="props"
                            v-if="user?.role?.name == 'Manager' || (user?.role?.name == 'Admin' && user?.dbnCode !== null) || user?.role?.name == 'Authenticated'"
                            link
                            class="hover:tw-bg-primary active:tw-bg-primary hover:tw-rounded-r-full active:tw-rounded-r-full hover:tw-w-11/12 active:tw-w-11/12">
                            <div class="tw-flex tw-ml-12">
                                <v-list-item-icon>
                                    <v-icon> mdi-domain </v-icon>
                                </v-list-item-icon>
                                <p class="tw-ml-6">{{ $t('reservedArea.sidebar.companies') }}</p>
                            </div>
                        </v-list-item>
                    </template>
                    <v-list-item
                        @click.stop="rail = !rail"
                        v-for="(dbn, i) in user?.dbns"
                        :key="i"
                        @click="setDbnCode(dbn?.code)">
                        <div class="tw-flex tw-ml-2">
                            <p
                                v-if="!user?.dbnCode"
                                class="tw-ml-6"
                                :class="user?.dbns[0].code == dbn?.code ? 'tw-text-primary' : ''">
                                {{ dbn?.name }}
                            </p>
                            <p
                                v-if="user?.dbnCode"
                                class="tw-ml-6"
                                :class="user?.dbnCode == dbn?.code ? 'tw-text-primary' : ''">
                                {{ dbn?.name }}
                            </p>
                        </div>
                    </v-list-item>
                </v-list-group>
            </v-list>
            <div class="tw-flex tw-justify-center tw-items-center tw-py-3 2xl:tw-py-7 tw-mt-auto tw-gap-x-8">
                <div class="tw-flex tw-justify-center tw-items-center">
                    <div>
                        <router-link
                            @click.stop="rail = !rail"
                            :to="{ name: 'MyPersonalData' }">
                            <v-img
                                aspect-ratio="1"
                                :src="getPicture()"
                                cover
                                class="tw-rounded-full tw-w-12 tw-h-12 2xl:tw-w-16 2xl:tw-h-16"></v-img>
                        </router-link>
                    </div>
                    <router-link
                        @click.stop="rail = !rail"
                        :to="{ name: 'MyPersonalData' }">
                        <p class="tw-text-lg tw-font-semibold tw-text-white tw-text-left tw-mx-5 tw-max-w-[8.125rem] tw-overflow-hidden tw-overflow-ellipsis">{{ user.username }}</p>
                    </router-link>
                </div>
                <div class="tw-flex tw-text-center tw-justify-center tw-w-14 tw-mt-1 tw-gap-x-4">
                    <span
                        class="fi fi-pt hover:tw-cursor-pointer"
                        @click="setLanguage('pt')"></span>
                    <span
                        class="fi fi-gb hover:tw-cursor-pointer"
                        @click="setLanguage('en')"></span>
                </div>
            </div>
            <v-list
                class="!tw-bg-secondary-light !tw-text-white tw-text-base tw-text-left"
                bg-color="tw-text-secondary-light">
                <v-list-item @click="logout()">
                    <div class="tw-flex tw-ml-12">
                        <v-list-item-icon>
                            <v-icon><v-img src="/images/ReservedArea/icons/logout.svg"></v-img></v-icon>
                        </v-list-item-icon>
                        <p class="tw-ml-6">{{ $t('reservedArea.sidebar.logout') }}</p>
                    </div>
                </v-list-item>
            </v-list>
        </div>
    </v-navigation-drawer>
</template>
<script setup lang="ts">
    import { computed, ref, watch, onBeforeMount } from 'vue';
    import { useDisplay } from 'vuetify';
    import { useAuthStore } from '@/store/modules/auth';
    import { useI18n } from 'vue-i18n';
    import { useLanguageStore } from '@/store/modules/language';
    import { useRouter } from 'vue-router';
    import { updateUser } from '@/api/user';

    const i18n = useI18n();
    const store = useLanguageStore();

    const desktopDrawer = ref(true);
    const mobileDrawer = ref(true);
    const rail = ref(true);

    const display = useDisplay();

    const setLanguage = (language: string) => {
        i18n.locale.value = language;
        store.setLanguage(language);
    };

    setLanguage(store.language);

    const isLgAndUp = computed(() => {
        const { lgAndUp } = display;
        return lgAndUp.value;
    });

    const emit = defineEmits(['isDesktopDrawer', 'isWithOverlay']);

    const authStore = useAuthStore();
    const token = authStore.getToken;
    const user = authStore.getUser;

    const $router = useRouter();

    // Check screen size before the component is inserted into the DOM
    // and set the drawer state accordingly
    onBeforeMount(() => {
        if (isLgAndUp.value) {
            desktopDrawer.value = true;
            emit('isDesktopDrawer', true);
        }
        if (!isLgAndUp.value) {
            desktopDrawer.value = false;
            emit('isDesktopDrawer', false);
        }
        if (rail.value) {
            emit('isWithOverlay', false);
        }
        if (!rail.value) {
            emit('isWithOverlay', true);
        }
    });

    watch(isLgAndUp, (value) => {
        if (value) {
            desktopDrawer.value = true;
            emit('isDesktopDrawer', true);
        } else {
            desktopDrawer.value = false;
            emit('isDesktopDrawer', false);
        }
    });

    const getPicture = () => {
        if (user.picture) {
            return import.meta.env.VITE_MEDIA_URL + user.picture?.url;
        }
        return '/images/ReservedArea/default_profile.jpg';
    };

    const setDbnCode = (dbnCode: string) => {
        updateUser(
            user.id,
            {
                data: {
                    dbnCode: dbnCode,
                },
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            },
        )
            .then((response) => {
                authStore.setUser({ ...user, dbnCode: response.data.data.dbnCode });
            })
            .finally(() => {
                if ($router.currentRoute.value.name !== 'Investment')
                    $router.push({ name: 'Investment' }).then(() => {
                        console.log('aqui');
                        $router.go(0);
                    });
                else $router.go(0);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const logout = () => {
        if (user?.role?.name === 'Admin') {
            updateUser(
                user.id,
                {
                    data: {
                        dbnCode: null,
                        dbns: [],
                    },
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                },
            )
                .then((response) => {
                    authStore.logout();
                })
                .finally(() => {
                    $router.push({ name: 'Auth-Login' });
                })
                .catch((error) => {
                    console.log(error);
                });
        } else {
            authStore.logout();
            $router.push({ name: 'Auth-Login' });
        }
    };
</script>

<style scoped></style>
<style>
    .nav-opened {
        width: 20.75rem !important;
        background-color: #131d3c;
    }

    .nav-closed {
        width: 3rem !important;
        height: 48px !important;
        left: 0.5625rem !important;
        top: 0.5625rem !important;
        border-radius: 0.625rem !important;
    }

    .nav-closed .v-navigation-drawer__content {
        overflow-y: hidden !important;
        overflow-x: hidden !important;
    }
</style>
