import { defineStore } from 'pinia';
import * as Sentry from '@sentry/vue';
import { email, nif } from '../../composables/rules';

export const useAuthStore = defineStore('auth', {
    state: () => ({
        token: '',
        user: null,
        isAuthenticated: false,
    }),
    getters: {
        getToken(state) {
            return state.token;
        },
        getUser(state) {
            return state.user;
        },
        getIsAuthenticated(state) {
            return state.isAuthenticated;
        },
    },
    actions: {
        setToken(token: string) {
            this.token = token;
        },
        setUser(user: User) {
            if (import.meta.env.VITE_SENTRY_DSN) {
                try {
                    Sentry.setUser({ email: user.email, id: user.id, username: user.NIF });
                } catch (error) {
                    console.error(error);
                }
            }

            this.user = user;
        },
        setIsAuthenticated(isAuthenticated: boolean) {
            this.isAuthenticated = isAuthenticated;
        },
        logout() {
            if (import.meta.env.VITE_SENTRY_DSN) {
                try {
                    Sentry.setUser(null);
                } catch (error) {
                    console.error(error);
                }
            }
            this.setToken('');
            this.setUser(null);
            this.setIsAuthenticated(false);
        },
    },
    persist: true,
});
