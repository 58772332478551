<template>
    <v-container
        class="tw-flex tw-flex-col tw-gap-4 container"
        v-if="loading == false">
        <div class="tw-flex tw-flex-col md:tw-justify-between md:tw-flex-row tw-mb-4">
            <h1 class="tw-font-bold tw-text-secondary !tw-text-center md:tw-text-left tw-text-3xl tw-underline tw-underline-offset-1 tw-decoration-primary tw-decoration-4">
                {{ $t('reservedArea.users.users') }}
            </h1>
        </div>
        <!-- Table -->
        <v-data-table-server
            v-model:items-per-page="pageSize"
            :items-per-page-text="$t('reservedArea.helpfulDocuments.filesPerPage')"
            :headers="headers"
            :items-length="total"
            :items-per-page-options="[
                { value: 10, title: '10' },
                { value: 25, title: '25' },
                { value: 50, title: '50' },
                { value: 100, title: '100' },
            ]"
            :items="users"
            :loading="loading"
            v-model:page="page"
            :search="search"
            show-expand
            v-model:expanded="expanded"
            class="!tw-rounded-lg tw-mt-"
            item-value="id"
            @update:options="onOptionsUpdated">
            <!-- filters -->
            <template #top>
                <v-row class="tw-px-4">
                    <v-col
                        cols="12"
                        class="tw-my-4">
                        <v-text-field
                            clearable
                            hide-details
                            :placeholder="$t('reservedArea.users.search')"
                            v-model="searchUser"
                            class="ma-2"
                            variant="outlined"
                            density="compact"></v-text-field>
                    </v-col>
                </v-row>
            </template>
            <!-- expanded row -->
            <template v-slot:expanded-row="{ columns, item }">
                <tr v-if="item.selectable?.dbns?.length > 0">
                    <td :colspan="columns.length">
                        <div class="tw-p-4">
                            <v-row
                                align-self="center"
                                v-for="(dbn, index) in item.selectable?.dbns"
                                :key="dbn.id">
                                <v-col
                                    cols="3"
                                    class="tw-justify-center">
                                    <p class="tw-font-semibold tw-text-secondary tw-text-sm">{{ $t('reservedArea.usersAdmin.code') }}</p>
                                    <p class="tw-text-secondary tw-text-sm">{{ dbn.code }}</p>
                                </v-col>
                                <v-col
                                    cols="3"
                                    class="tw-justify-center">
                                    <p class="tw-font-semibold tw-text-secondary tw-text-sm">{{ $t('reservedArea.usersAdmin.name') }}</p>
                                    <p class="tw-text-secondary tw-text-sm">{{ dbn.name }}</p>
                                </v-col>
                                <v-col
                                    cols="4"
                                    class="tw-justify-center">
                                    <p class="tw-font-semibold tw-text-secondary tw-text-sm">{{ $t('reservedArea.usersAdmin.email') }}</p>
                                    <p class="tw-text-secondary tw-text-sm">{{ dbn.email }}</p>
                                </v-col>
                                <v-col
                                    cols="2"
                                    class="tw-justify-center">
                                    <p class="tw-font-semibold tw-text-secondary tw-text-sm">{{ $t('reservedArea.usersAdmin.nif') }}</p>
                                    <p class="tw-text-secondary tw-text-sm">{{ dbn.nif }}</p>
                                </v-col>
                                <v-divider v-if="index !== item.selectable.dbns.length - 1"></v-divider>
                            </v-row>
                        </div>
                    </td>
                </tr>
            </template>
            <!-- id -->
            <template #[`item.name`]="{ item }">
                <div>
                    <p
                        v-if="item.selectable.id"
                        class="tw-font-semibold tw-text-secondary tw-text-left tw-text-sm">
                        {{ item.selectable.id }}
                    </p>
                    <p
                        v-else
                        class="tw-font-semibold tw-text-secondary tw-text-left tw-text-sm">
                        -
                    </p>
                </div>
            </template>
            <!-- name -->
            <template #[`item.username`]="{ item }">
                <div>
                    <p
                        v-if="item.selectable.username"
                        class="tw-font-semibold tw-text-secondary tw-text-left tw-text-sm">
                        {{ item.selectable.username }}
                    </p>
                    <p
                        v-else
                        class="tw-font-semibold tw-text-secondary tw-text-left tw-text-sm">
                        -
                    </p>
                </div>
            </template>
            <!-- email -->
            <template #[`item.email`]="{ item }">
                <div>
                    <p
                        v-if="item.selectable.email"
                        class="tw-font-semibold tw-text-secondary tw-text-left tw-text-sm">
                        {{ item.selectable.email }}
                    </p>
                    <p
                        v-else
                        class="tw-font-semibold tw-text-secondary tw-text-left tw-text-sm">
                        -
                    </p>
                </div>
            </template>
            <!-- nif -->
            <template #[`item.nif`]="{ item }">
                <div>
                    <p
                        v-if="item.selectable.NIF"
                        class="tw-font-semibold tw-text-secondary tw-text-left tw-text-sm">
                        {{ item.selectable.NIF }}
                    </p>
                    <p
                        v-else
                        class="tw-font-semibold tw-text-secondary tw-text-left tw-text-sm">
                        {{ item.selectable?.dbns[0]?.nif  ? item.selectable?.dbns[0]?.nif : '-' }}
                    </p>
                </div>
            </template>
            <template #[`item.actions`]="{ item }">
                <v-btn
                    variant="flat"
                    v-if="item.selectable?.dbns.length > 0"
                    type="submit"
                    @click="submit(item.selectable?.dbns)"
                    class="bg-primary text-black font-weight-bold !tw-text-sm !tw-py-1.5"
                    max-height="none"
                    height="auto"
                    rounded="xs"
                    size="x-large">
                    {{ $t('reservedArea.usersAdmin.enter') }}
                </v-btn>
                <v-chip
                    v-else
                    variant="tonal"
                    color="red"
                    rounded="lg"
                    append-icon="mdi-alert-circle">
                    {{ $t('reservedArea.usersAdmin.noDbn') }}
                </v-chip>
            </template>
        </v-data-table-server>
    </v-container>
    <div
        v-if="loading"
        class="tw-h-full tw-flex tw-items-center tw-justify-center">
        <v-progress-circular
            indeterminate
            color="primary"
            model-value="20"></v-progress-circular>
    </div>
</template>

<script setup lang="ts">
    import { ref, watch } from 'vue';
    import { VDataTableServer } from 'vuetify/labs/VDataTable';
    import { useI18n } from 'vue-i18n';
    import { useRouter } from 'vue-router';
    import { getUsers, updateUser } from '@/api/user';
    import { useAuthStore } from '@/store/modules/auth';
    import { phone } from '../../composables/rules';

    const { t } = useI18n();

    const $router = useRouter();

    const headers = ref([
        {
            title: t('reservedArea.usersAdmin.id'),
            align: 'start',
            sortable: false,
            key: 'id',
        },
        { title: t('reservedArea.usersAdmin.name'), key: 'username' },
        { title: t('reservedArea.usersAdmin.email'), key: 'email' },
        { title: t('reservedArea.usersAdmin.nif'), key: 'nif' },
        { title: '', key: 'actions', align: 'center' },
    ]);
    const loading = ref(true);
    const search = ref('');
    const searchUser = ref('');

    //store token
    const authStore = useAuthStore();
    const token = authStore.getToken;
    const me = authStore.getUser;

    const users = ref([]);
    const pageCount = ref(0);
    const pageSize = ref(15);
    const page = ref(1);
    const total = ref(0);
    const firstUpdate = ref(true);
    const expanded = ref([]);

    const sort = ref([
        {
            key: 'id',
            order: 'asc',
        },
    ]);

    //watch search user
    watch(searchUser, () => {
        search.value = searchUser.value;
    });

    const onOptionsUpdated = (options) => {
        if (firstUpdate.value) {
            firstUpdate.value = false;
            return;
        }
        page.value = options.page;
        pageSize.value = options.itemsPerPage;
        sort.value = options.sortBy;
        fetchUsers();
    };

    const fetchUsers = () =>
        getUsers(
            {
                filters: {
                    $or: [
                        {
                            username: {
                                $contains: searchUser.value,
                            },
                        },
                        {
                            email: {
                                $contains: searchUser.value,
                            },
                        },
                        {
                            NIF: {
                                $contains: searchUser.value,
                            },
                        },
                        {
                            dbns: {
                                nif: {
                                    $contains: searchUser.value,
                                },
                            }
                        }
                    ],
                },
                populate: 'dbns',
                pagination: {
                    page: page.value,
                    pageSize: pageSize.value,
                },
                sort: sort.value.map((item) => {
                    return {
                        [item.key]: item.order,
                    };
                }),
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            },
        )
            .then((response) => {
                users.value = response.data.data;
                total.value = response.data.meta.pagination.total;
                pageCount.value = response.data.meta.pagination.pageCount;
            })
            .finally(() => {
                loading.value = false;
            })
            .catch((error) => {
                console.log(error);
            });

    fetchUsers();

    const submit = (dbns) => {
        //update user
        updateUser(
            me.id,
            {
                data: {
                    dbnCode: dbns[0].code,
                    dbns: dbns.map((dbn) => {
                        return dbn.id;
                    }),
                },
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            },
        )
            .then((response) => {
                useAuthStore().setUser({
                    ...me,
                    dbnCode: response.data.data.dbnCode,
                    dbns: dbns,
                });
            })
            .finally(() => {
                $router.go(0);
            })
            .then(() => {
                window.location.href = $router.resolve({ name: 'Investment' }).href;
            })
            .catch((error) => {
                console.log(error);
            });
    };
</script>

<style scoped></style>

<style>
    .v-data-table-header__content {
        color: #b0b0b0;
        font-weight: 600 !important;
    }
    .v-data-table .v-table__wrapper > table > thead > tr > th,
    .v-data-table .v-table__wrapper > table tbody > tr > th {
        background-color: #f8f8f8;
    }
</style>
