<template>
    <v-app>
        <router-view></router-view>
    </v-app>
</template>

<script setup lang="ts">
    import { useAuthStore } from '@/store/modules/auth';
    import { useLanguageStore } from './store/modules/language';
    import { me } from '@/api/user';
    import { useRouter } from 'vue-router';

    const authStore = useAuthStore();
    const languageStore = useLanguageStore();
    const $router = useRouter();

    // console.log(languageStore.language);

    async function init() {
        try {
            const { data } = await me(
                {
                    populate: ['role', 'dbns', 'children'],
                },
                {
                    headers: {
                        Authorization: `Bearer ${authStore.getToken}`,
                    },
                },
            );
            authStore.setUser(data.user);
        } catch (error) {
            authStore.logout();
            $router.push({ name: 'Auth-Login' });
        }
    }

    if (authStore.isAuthenticated) {
        init();
    }
</script>
