<template>
    <v-main>
        <!--Desktop-->
        <div class="!tw-hidden lg:!tw-block">
            <div class="tw-flex">
                <div class="image-container">
                    <v-img cover height="100%" src="/images/Auth/banner.png"></v-img>
                </div>
                <div class="form-container">
                    <router-view></router-view>
                </div>
            </div>
        </div>
        <!--Mobile-->
        <div class="!tw-block lg:!tw-hidden tw-flex-col tw-h-full">
            <v-sheet rounded="t-2xl" class="flex-grow-1 tw-h-full">
                <router-view></router-view>
            </v-sheet>
        </div>
    </v-main>
</template>

<script setup lang="ts"></script>

<style scoped>
    .img-container {
        height: 22vh;
    }
    .image-container {
        width: 65%;
        height: 100vh;
    }
    .form-container {
        width: 35%;
        height: 100vh;
    }
</style>
