<template>
    <v-container
        class="container"
        v-if="isLoading == false">
        <v-row>
            <v-col cols="12"
                ><h1 class="tw-font-bold tw-text-secondary tw-text-center md:tw-text-left tw-text-3xl tw-underline tw-underline-offset-1 tw-decoration-primary tw-decoration-4 tw-mb-4">
                    {{ $t('reservedArea.novelties.novelties') }}
                </h1></v-col
            >
            <!-- Breadcrumbs -->
            <!-- <v-col cols="12" class="tw-hidden md:tw-block">
                <div class="tw-text-lg tw-text-secondary tw-text-left">
                    <v-breadcrumbs :items="breadCrumbItems" active-class="!tw-font-semibold" class="!tw-pl-0">
                        <template v-slot:divider>
                            <v-icon icon="mdi-chevron-right"></v-icon>
                        </template>
                    </v-breadcrumbs>
                </div>
            </v-col> -->
            <!-- Filters -->
            <v-col
                cols="12"
                class="tw-text-center md:tw-text-left">
                <v-btn
                    variant="flat"
                    type="submit"
                    class="font-weight-bold !tw-text-base !tw-py-1.5 tw-mr-3.5 tw-mb-4"
                    :class="allFiltersApplied ? 'bg-secondary text-white' : 'bg-gray-light text-black'"
                    max-height="none"
                    height="auto"
                    rounded="xs"
                    size="x-large"
                    @click="toggleFilter('all')">
                    {{ $t('reservedArea.novelties.all') }}
                </v-btn>
                <v-btn
                    variant="flat"
                    type="submit"
                    class="font-weight-bold !tw-text-base !tw-py-1.5 tw-mr-3.5 tw-mb-4"
                    :class="filters.includes('BlueCrow') || filters.includes('all') ? 'bg-secondary text-white' : 'bg-gray-light text-black'"
                    max-height="none"
                    height="auto"
                    rounded="xs"
                    size="x-large"
                    @click="toggleFilter('BlueCrow')">
                    {{ $t('reservedArea.novelties.blueCrow') }}
                </v-btn>
                <v-btn
                    variant="flat"
                    type="submit"
                    class="font-weight-bold !tw-text-base !tw-py-1.5 tw-mr-3.5 tw-mb-4"
                    :class="filters.includes('Fundos') || filters.includes('Funds') || filters.includes('all') ? 'bg-secondary text-white' : 'bg-gray-light text-black'"
                    max-height="none"
                    height="auto"
                    rounded="xs"
                    size="x-large"
                    @click="toggleFilter(language === 'pt' ? 'Fundos' : 'Funds')">
                    {{ $t('reservedArea.novelties.funds') }}
                </v-btn>
                <v-btn
                    variant="flat"
                    type="submit"
                    class="font-weight-bold !tw-text-base !tw-py-1.5 tw-mr-3.5 tw-mb-4"
                    :class="filters.includes('Mercado') || filters.includes('Market') || filters.includes('all') ? 'bg-secondary text-white' : 'bg-gray-light text-black'"
                    max-height="none"
                    height="auto"
                    rounded="xs"
                    size="x-large"
                    @click="toggleFilter(language === 'pt' ? 'Mercado' : 'Market')">
                    {{ $t('reservedArea.novelties.market') }}
                </v-btn>
                <v-btn
                    variant="flat"
                    type="submit"
                    class="font-weight-bold !tw-text-base !tw-py-1.5 tw-mr-3.5 tw-mb-4"
                    :class="filters.includes('Participadas') || filters.includes('Participated') || filters.includes('all') ? 'bg-secondary text-white' : 'bg-gray-light text-black'"
                    max-height="none"
                    height="auto"
                    rounded="xs"
                    size="x-large"
                    @click="toggleFilter(language === 'pt' ? 'Participadas' : 'Participated')">
                    {{ $t('reservedArea.novelties.participated') }}
                </v-btn>
                <v-btn
                    variant="flat"
                    type="submit"
                    class="font-weight-bold !tw-text-base !tw-py-1.5 tw-mr-3.5 tw-mb-4"
                    :class="filters.includes('Produtos e Serviços') || filters.includes('Products & Services') || filters.includes('all') ? 'bg-secondary text-white' : 'bg-gray-light text-black'"
                    max-height="none"
                    height="auto"
                    rounded="xs"
                    size="x-large"
                    @click="toggleFilter(language === 'pt' ? 'Produtos e Serviços' : 'Products & Services')">
                    {{ $t('reservedArea.novelties.productsAndServices') }}
                </v-btn>
            </v-col>
            <!-- Cards -->
            <v-col
                cols=""
                xs="12"
                sm="12"
                md="6"
                lg="6"
                xl="4"
                v-for="novelty in novelties"
                :key="novelty">
                <v-card
                    variant="flat"
                    rounded="lg">
                    <v-img
                        v-if="novelty.picture?.url"
                        cover
                        :src="`${mediaUrl}${novelty.picture.url}`"
                        height="200px"></v-img>
                    <v-img
                        v-if="!novelty.picture?.url"
                        contain
                        src="/images/blue_crow_crow_only.jpg"
                        height="200px"></v-img>
                    <v-card-title class="!tw-font-bold !tw-text-base tw-text-secondary tw-text-center md:tw-text-left"> {{ novelty.title }} </v-card-title>
                    <v-card-text class="tw-h-[120px] tw-mb-2 tw-line-clamp-6">
                        <span
                            class="novelty-description-span !tw-h-26"
                            v-html="novelty.description"></span>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn
                            variant="flat"
                            :to="{ path: `novelty/${novelty.id}` }"
                            type="submit"
                            class="bg-primary text-black font-weight-bold !tw-text-base !tw-py-1.5 tw-ml-1.5 tw-my-1.5"
                            max-height="none"
                            height="auto"
                            rounded="xs"
                            size="x-large">
                            {{ $t('reservedArea.novelties.seeMore') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
    <div
        class="text-center"
        v-if="pageCount > 0">
        <v-pagination
            elevation="0"
            total-visible="5"
            class="tw-mb-2"
            color="primary"
            v-model="page"
            :length="pageCount"
            @update:model-value="fetchNovelties"
            rounded="lg"
            density="comfortable"></v-pagination>
    </div>
    <div
        v-if="isLoading"
        class="tw-h-full tw-flex tw-items-center tw-justify-center">
        <v-progress-circular
            indeterminate
            color="primary"
            model-value="20"></v-progress-circular>
    </div>
    <div
        v-if="!isLoading && novelties.length == 0"
        class="tw-items-center tw-justify-center tw-flex tw-gap-x-2 tw-p-4 md:tw-p-0 tw-text-center">
        <p class="tw-font-semibold">{{ $t('reservedArea.novelties.notFound') }} </p>
        <v-icon
            class="tw-text-primary"
            icon="mdi-alert-circle"></v-icon>
    </div>
</template>

<script setup lang="ts">
    import { ref, watch, computed } from 'vue';
    import { useI18n } from 'vue-i18n';
    import { getNovelties } from '@/api/novelty';
    import { useLanguageStore } from '@/store/modules/language';
    import { useAuthStore } from '@/store/modules/auth';
    import { useRouter } from 'vue-router';

    const { t } = useI18n();

    const $router = useRouter();
    //breadcrumbs
    // const breadCrumbItems = ref([
    //     {
    //         title: t('reservedArea.breadcrumbs.dashboard'),
    //         disabled: false,
    //         href: '/reserved-area/dashboard',
    //     },
    //     {
    //         title: t('reservedArea.breadcrumbs.novelties'),
    //         disabled: false,
    //         active: $router.currentRoute.value.name == 'Novelty' ? true : false,
    //         href: '/reserved-area/novelty',
    //     },
    // ]);

    const filters = ref([]);
    const allFiltersApplied = ref(false);

    const mediaUrl = ref(import.meta.env.VITE_MEDIA_URL);

    const languageStore = useLanguageStore();
    const language = computed(() => languageStore.getLanguage);

    const authStore = useAuthStore();
    const token = authStore.getToken;

    const novelties = ref([]);
    const page = ref(1);
    const pageSize = ref(12);
    const pageCount = ref(0);

    const isLoading = ref(true);

    watch(language, () => {
        filters.value = [];
        fetchNovelties();
    });

    const toggleFilter = (filterName: string) => {
        if (filterName == 'all') {
            if (allFiltersApplied.value == true) {
                allFiltersApplied.value = false;
                filters.value = [];
            } else {
                filters.value = [
                    'BlueCrow',
                    language.value == 'pt' ? 'Fundos' : 'Funds',
                    language.value == 'pt' ? 'Mercado' : 'Market',
                    language.value == 'pt' ? 'Participadas' : 'Participated',
                    language.value == 'pt' ? 'Produtos e Serviços' : 'Products & Services',
                ];
                allFiltersApplied.value = true;
            }
        } else if (filters.value.includes(filterName)) {
            filters.value = filters.value.filter((filter) => filter != filterName);
        } else {
            filters.value.push(filterName);
        }
        fetchNovelties();
    };

    const fetchNovelties = () =>
        getNovelties(
            {
                populate: ['picture', 'categories'],
                locale: language.value == 'pt' ? 'pt' : 'en',
                filters: {
                    $or: [
                        {
                            categories: {
                                name: {
                                    $containsi: filters.value,
                                },
                            },
                        },
                    ],
                },
                pagination: {
                    page: page.value,
                    pageSize: pageSize.value,
                },
                sort: 'createdAt:desc',
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            },
        ).then((response) => {
            novelties.value = response.data.data;
            pageCount.value = response.data.meta.pagination.pageCount;
            isLoading.value = false;
        });

    fetchNovelties();
</script>
