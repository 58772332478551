<template>
    <Sidebar @isDesktopDrawer="updateLayoutClass" @isWithOverlay="updateOverlay"/>
    <v-main :class="{ 'desktop-layout': isDesktopDrawerOpen, 'mobile-layout': !isDesktopDrawerOpen }" class="tw-bg-gray-lighter-lighter" :scrollable="withOverlay">
        <div v-if="withOverlay" class="overlay"></div>
        <router-view></router-view>
    </v-main>
</template>

<script setup lang="ts">
    import Sidebar from './Sidebar.vue';
    import { ref } from 'vue';

    const isDesktopDrawerOpen = ref(true);
    const withOverlay = ref(false);

    const updateLayoutClass = (value) => {
        isDesktopDrawerOpen.value = value;
    };

    const updateOverlay = (value) => {
        withOverlay.value = value;
    };
</script>
<style scoped>
    .desktop-layout {
        --v-layout-left: 23rem !important;
        --v-layout-right: 2.5rem !important;
    }
    .mobile-layout {
        --v-layout-left: 0 !important;
    }
    .overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(33, 33, 33, 0.46);
        z-index: 6;
        pointer-events: auto !important;
    }
</style>
<style></style>
