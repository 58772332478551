<template>
    <v-container class="container tw-flex tw-flex-col tw-gap-4">
        <div class="tw-flex tw-flex-col md:tw-justify-between md:tw-flex-row">
            <h1 class="tw-font-bold tw-text-secondary tw-text-center md:tw-text-left tw-text-3xl tw-underline tw-underline-offset-1 tw-decoration-primary tw-decoration-4 tw-mb-4">
                {{ $t('reservedArea.addUserRequest.userData') }}
            </h1>
            <!-- Upload documents / Request data change (>768px) -->
            <div class="tw-hidden md:tw-block tw-text-center md:tw-text-left tw-mt-4 md:tw-mt-0">
                <v-btn
                    variant="flat"
                    class="bg-gray-light text-black font-weight-bold !tw-text-base !tw-py-1.5 tw-mr-3.5 focus:!tw-bg-secondary focus:!tw-text-white"
                    max-height="none"
                    height="auto"
                    rounded="xs"
                    size="x-large"
                    @click="requestAddUser()">
                    {{ $t('reservedArea.addUserRequest.requestAddUser') }}
                </v-btn>
            </div>
        </div>
        <!-- Breadcrumbs -->
        <div class="tw-text-lg tw-text-secondary tw-text-left tw-hidden md:tw-block">
            <v-breadcrumbs
                :items="breadCrumbItems"
                active-class="!tw-font-semibold"
                class="!tw-pl-0">
                <template v-slot:divider>
                    <v-icon icon="mdi-chevron-right"></v-icon>
                </template>
            </v-breadcrumbs>
        </div>
        <!-- success alert >768px -->
        <div class="tw-hidden md:tw-block">
            <div
                class="tw-flex tw-justify-end tw-z-10 !tw-mt-4 !tw-mb-2"
                v-show="success">
                <v-alert
                    class="!tw-justify-start"
                    dense
                    variant="tonal"
                    type="success"
                    >{{ $t('reservedArea.addUserRequest.successText') }}</v-alert
                >
                <div
                    class="tw-absolute tw-mr-2 tw-mt-1 tw-text-alert-success tw-cursor-pointer"
                    @click="reloadPage()">
                    X
                </div>
            </div>
        </div>
        <!-- person/company form -->
        <div class="tw-bg-white tw-rounded-lg">
            <p class="tw-font-bold tw-text-secondary tw-text-left tw-text-xl tw-ml-8 tw-mt-8">{{ $t('reservedArea.addUserRequest.userData') }}</p>
            <v-form
                class="tw-ml-4 tw-mt-3"
                v-model="isFormValid"
                :disabled="isDisabled">
                <v-container class="">
                    <v-row>
                        <!-- name -->
                        <v-col
                            cols="12"
                            md="6">
                            <p class="tw-font-semibold tw-text-secondary tw-text-left tw-text-base tw-mb-3 tw-truncate">{{ $t('reservedArea.addUserRequest.name') }}</p>
                            <v-text-field
                                v-model="name"
                                :rules="[
                                    (v) => rules.required(v, $t('reservedArea.addUserRequest.requiredField')),
                                    (v) => rules.name(v, $t('reservedArea.addUserRequest.invalidName')),
                                    // (v) => rules.nameAlreadyExists(v, token, $t('reservedArea.addUserRequest.nameAlreadyExists')),
                                ]"
                                :label="$t('reservedArea.addUserRequest.name')"
                                variant="outlined"></v-text-field>
                        </v-col>
                        <!-- email -->
                        <v-col
                            cols="12"
                            md="6">
                            <p class="tw-font-semibold tw-text-secondary tw-text-left tw-text-base tw-mb-3">{{ $t('reservedArea.addUserRequest.email') }}</p>
                            <v-text-field
                                v-model="email"
                                :rules="[
                                    (v) => rules.required(v, $t('reservedArea.addUserRequest.requiredField')),
                                    (v) => rules.email(v, $t('reservedArea.addUserRequest.invalidEmail')),

                                    // (v) => rules.emailAlreadyExists(v, token, $t('reservedArea.addUserRequest.emailAlreadyExists')),
                                ]"
                                :label="$t('reservedArea.addUserRequest.email')"
                                variant="outlined"></v-text-field>
                        </v-col>
                        <!-- job title -->
                        <v-col
                            cols="12"
                            md="6">
                            <p class="tw-font-semibold tw-text-secondary tw-text-left tw-text-base tw-mb-3">{{ $t('reservedArea.addUserRequest.jobTitle') }}</p>
                            <v-text-field
                                v-model="jobTitle"
                                :label="$t('reservedArea.addUserRequest.jobTitle')"
                                variant="outlined"></v-text-field>
                        </v-col>
                    </v-row>
                </v-container>
            </v-form>
        </div>
        <!-- upload documents form -->
        <div class="tw-bg-white tw-rounded-lg tw-mt-9">
            <p class="tw-font-bold tw-text-secondary tw-text-left tw-text-xl tw-ml-8 tw-mt-8">{{ $t('reservedArea.addUserRequest.uploadDocuments') }}</p>
            <v-form
                class="tw-ml-4 tw-mt-3"
                :disabled="isDisabled"
                ref="documentsForm">
                <v-container class="">
                    <v-row>
                        <v-col
                            cols="12"
                            md="12">
                            <v-file-input
                                v-model="files"
                                :label="$t('reservedArea.addUserRequest.uploadDocuments')"
                                outlined
                                multiple
                                @change="handleFilesUpload">
                            </v-file-input>
                        </v-col>
                    </v-row>
                </v-container>
            </v-form>
        </div>
        <!-- Upload documents / Request data change (<768px) -->
        <div class="tw-flex tw-flex-col tw-items-center md:tw-hidden tw-text-center tw-pb-8 tw-mt-2">
            <v-btn
                variant="flat"
                type="submit"
                class="bg-gray-light text-black font-weight-bold !tw-text-base !tw-py-1.5 focus:!tw-bg-secondary focus:!tw-text-white"
                max-height="none"
                height="auto"
                rounded="xs"
                size="x-large"
                @click="requestAddUser()">
                {{ $t('reservedArea.addUserRequest.requestAddUser') }}
            </v-btn>
        </div>
        <!-- success alert <768px -->
        <div class="tw-block md:tw-hidden">
            <div
                class="tw-flex tw-justify-end tw-z-10"
                v-show="success">
                <v-alert
                    class="!tw-justify-start"
                    dense
                    variant="tonal"
                    type="success"
                    >{{ $t('reservedArea.addUserRequest.successText') }}</v-alert
                >
                <div
                    class="tw-absolute tw-mr-2 tw-mt-1 tw-text-alert-success tw-cursor-pointer"
                    @click="reloadPage()">
                    X
                </div>
            </div>
        </div>
    </v-container>
</template>

<script setup lang="ts">
    import { ref } from 'vue';
    import { createAddUserRequest } from '@/api/addUserRequest';
    import { uploadFiles } from '@/api/upload';
    import { useRouter } from 'vue-router';
    import { useAuthStore } from '@/store/modules/auth';
    import { useI18n } from 'vue-i18n';
    import rules from '@/composables/rules';

    const { t: $t } = useI18n();

    const $router = useRouter();

    //breadcrumbs
    const breadCrumbItems = ref([
        // {
        //     title: $t('reservedArea.breadcrumbs.dashboard'),
        //     disabled: false,
        //     href: '/reserved-area/dashboard',
        // },
        {
            title: $t('reservedArea.breadcrumbs.users'),
            disabled: false,
            active: $router.currentRoute.value.name == 'Users' ? true : false,
            href: '/reserved-area/users',
        },
        {
            title: $t('reservedArea.breadcrumbs.userData'),
            disabled: false,
            active: $router.currentRoute.value.name == 'AddUserRequest' ? true : false,
            href: '/reserved-area/users/add-user-request',
        },
    ]);

    const isDisabled = ref(false);

    const isFormValid = ref(false);
    const name = ref('');
    const email = ref('');
    const jobTitle = ref('');
    const files = ref([]);
    const success = ref(false);
    const authStore = useAuthStore();
    const token = authStore.getToken;

    const requestAddUser = () => {
        if (isFormValid.value) {
            createAddUserRequest(
                {
                    data: {
                        user: {
                            id: authStore.getUser.id,
                        },
                        name: name.value ? name.value : null,
                        email: email.value ? email.value : null,
                        jobTitle: jobTitle.value ? jobTitle.value : null,
                        files: files.value ? files.value : null,
                    },
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                },
            ).then((response) => {
                success.value = true;
                isDisabled.value = true;
            });
        }
    };

    const handleFilesUpload = () => {
        uploadFiles(files.value, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'multipart/form-data',
            },
        }).then((response) => {
            files.value = response.data;
        });
    };

    const router = useRouter();

    const reloadPage = () => {
        router.go(0);
    };
</script>

<style scoped></style>
<style>
    .v-input--density-default {
        --v-input-padding-top: 0.2rem;
    }
</style>
