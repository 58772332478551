/**
 * plugins/index.ts
 *
 * Automatically included in `./src/main.ts`
 */

// Plugins
import { loadFonts } from './webfontloader';
import vuetify from './vuetify';
import pinia from '../store';
import router from '../router';
import i18n from './i18n';
import './yup';
import * as Sentry from '@sentry/vue';

// Types
import type { App } from 'vue';

export function registerPlugins(app: App) {
    loadFonts();

    if (import.meta.env.VITE_SENTRY_DSN) {
        Sentry.init({
            app,
            dsn: import.meta.env.VITE_SENTRY_DSN,
            integrations: [Sentry.browserTracingIntegration({ router }), Sentry.replayIntegration(), Sentry.captureConsoleIntegration()],
            tunnel: import.meta.env.VITE_SENTRY_TUNNEL,
            tracesSampleRate: 1.0,
            replaysSessionSampleRate: 0.1,
            replaysOnErrorSampleRate: 1.0,
            trackComponents: true,
            logErrors: true,
            attachProps: true,
            attachStacktrace: true,
        });
    }
    if (import.meta.env.VITE_SENTRY_DSN) {
        try {
            Sentry.setTag('io_version', import.meta.env.VITE_APP_VERSION);
        } catch (error) {
            console.error(error);
        }
    }

    app.use(vuetify).use(router).use(pinia).use(i18n);
}
