import { getUsers } from '@/api/user';
import { get } from 'lodash';
// import { nameAlreadyExists } from './rules';

export function required(value: any, message: string) {
    return !!value || message;
}

export function email(value: any, message: string) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@(([^<>()\[\]\\.,;:\s@"]+\.)+[^<>()\[\]\\.,;:\s@"]{2,})$/i;
    return re.test(value) || message;
}

export function name(value: any, message: string) {
    const re = /^[a-zA-Z\W]{3,}$/;
    return re.test(value) || message;
}

export function emailOrNif(value: any, message: string) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@|([0-9]{9})$/i;
    return re.test(value) || message;
}

export function min(value: any, min: number, message: string) {
    return value.length >= min || message;
}

export function comparePassword(value: any, other: any, message: string) {
    return value === other || message;
}

export function nipc(value: any, message: string) {
    const re = /^[0-9]{9}$/;
    return re.test(value) || message;
}

export function nif(value: any, message: string) {
    const re = /^[0-9]{9}$/;
    return re.test(value) || message;
}

export function zipCode(value: any, message: string) {
    const re = /^[0-9]{4}-[0-9]{3}$/;
    return re.test(value) || message;
}

export function cae(value: any, message: string) {
    const re = /^[0-9]{5}$/;
    return re.test(value) || message;
}

export function phone(value: any, message: string) {
    const re = /^(\+351)?[0-9]{9}$/;
    return re.test(value) || message;
}

// export async function nameAlreadyExists(value: any, token: any, message: string) {
//     try {
//         const response = await getUsers({ filters: { username: value } }, { headers: { Authorization: `Bearer ${token}` } });
//         return response.data.data.length > 0 ? message : true;
//     } catch (error) {
//         console.error(error);
//         return "An error occurred";
//     }
// }

// export async function emailAlreadyExists(value: any, token: any, message: string) {
//     try {
//         const response = await getUsers({ filters: { email: value } }, { headers: { Authorization: `Bearer ${token}` } });
//         return response.data.data.length > 0 ? message : true;
//     } catch (error) {
//         console.error(error);
//         return "An error occurred";
//     }
// }


export default {
    required,
    email,
    name,
    emailOrNif,
    min,
    comparePassword,
    nipc,
    nif,
    zipCode,
    cae,
    phone,
    // nameAlreadyExists,
    // emailAlreadyExists,
};
